import { alpha, SxProps, Theme } from '@mui/material'
import { theme } from 'theme'

export const no_select: SxProps<Theme> = {
  userSelect: 'none',
  MozUserSelect: 'none',
  WebkitUserSelect: 'none',
  msUserSelect: 'none',
}

export const hover_box_shadow: SxProps<Theme> = {
  '&:hover': {
    boxShadow: `0px 3px 7px ${alpha(theme.palette.info.dark, 0.5)}`,
  },
}

export const multilineEllipsis = (lines: number): SxProps<Theme> => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: lines,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})
