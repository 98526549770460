import { Solicitation, useGetNavigationDataQuery } from 'generated/graphql'
import { isEqual } from 'lodash'
import { UserContext } from 'pages/Login/User/providers/UserProvider'
import { useContext, useEffect, useState } from 'react'

export interface NavigationPageState {
  solicitations: Solicitation[]
}

export function useNavigationPageState(): NavigationPageState {
  const userContext = useContext(UserContext)
  const [solicitations, setSolicitations] = useState<Solicitation[]>([])
  const userId = userContext.user.userId
  const tenantId = userContext.user.tenant.id

  const [query] = useGetNavigationDataQuery({
    variables: { userId, tenantId },
    pause: userId === '' || tenantId === '',
  })

  useEffect(() => {
    if (query?.data) {
      const data = query.data.listSolicitationByUser as Solicitation[]
      if (!isEqual(data, solicitations)) {
        setSolicitations(data ?? [])
      }
    }
  }, [query, solicitations, setSolicitations])

  return {
    solicitations,
  }
}
