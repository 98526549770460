import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
};

export type ActiveInstrument = {
  __typename?: 'ActiveInstrument';
  id: Scalars['ID'];
  externalId: Scalars['ID'];
  name: Scalars['String'];
  ceilingValue: Scalars['Float'];
  businessUnit: Scalars['String'];
  contractor: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  contractingPoc?: Maybe<Scalars['String']>;
  pmoPoc?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  followOnReq?: Maybe<Scalars['Boolean']>;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  cparsDueDate?: Maybe<Scalars['Date']>;
  fundedAmount: Scalars['Float'];
  dispersedAmount: Scalars['Float'];
  uloAvailable?: Maybe<Scalars['Float']>;
  ftes?: Maybe<Scalars['Float']>;
  aasFtes?: Maybe<Scalars['Float']>;
  expirationStatus?: Maybe<ExpirationStatus>;
  nextFyFundingReq?: Maybe<Scalars['Float']>;
  fundingType?: Maybe<FundingType>;
};

export type Annotation = {
  __typename?: 'Annotation';
  id: Scalars['ID'];
  text: Scalars['String'];
  startPage: Scalars['Int'];
  startLine: Scalars['Int'];
  startIndex: Scalars['Int'];
  endPage: Scalars['Int'];
  endLine: Scalars['Int'];
  endIndex: Scalars['Int'];
};

export type AnnotationInput = {
  id?: Maybe<Scalars['ID']>;
  text: Scalars['String'];
  startPage: Scalars['Int'];
  startLine: Scalars['Int'];
  startIndex: Scalars['Int'];
  endPage: Scalars['Int'];
  endLine: Scalars['Int'];
  endIndex: Scalars['Int'];
};

export enum CommunicationType {
  Event = 'EVENT',
  Sync = 'SYNC'
}

export type Contract = {
  __typename?: 'Contract';
  id: Scalars['ID'];
  externalId: Scalars['ID'];
  name: Scalars['String'];
  businessUnit: Scalars['String'];
  dateUpdatedAt?: Maybe<Scalars['Date']>;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  ceilingValue: Scalars['Float'];
  priority?: Maybe<Priority>;
  contractingOffice: Scalars['String'];
  contractType?: Maybe<Scalars['String']>;
  contractOptions: Array<ContractOption>;
  activeInstruments: Array<ActiveInstrument>;
};

export type ContractOption = {
  __typename?: 'ContractOption';
  id: Scalars['ID'];
  popType: PopType;
  popName: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  value: Scalars['Float'];
};

export type ContractingAction = {
  __typename?: 'ContractingAction';
  id: Scalars['String'];
  name: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  completedDate?: Maybe<Scalars['Date']>;
};

export type Criteria = {
  __typename?: 'Criteria';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  rating?: Maybe<RatingValue>;
};



export type DeleteEntityResponse = EventResponse | DeletedEntity;

export type DeletedEntity = {
  __typename?: 'DeletedEntity';
  id: Scalars['ID'];
};

export type Discovery = {
  __typename?: 'Discovery';
  id: Scalars['ID'];
  author: User;
  annotations: Array<Annotation>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  rating: RatingValue;
  criteria: Criteria;
  feedback: Array<DiscoveryFeedback>;
  review?: Maybe<Scalars['DateTime']>;
  sseb?: Maybe<Scalars['DateTime']>;
};

export type DiscoveryFeedback = {
  __typename?: 'DiscoveryFeedback';
  id: Scalars['ID'];
  comment: Scalars['String'];
  sentBy: User;
  acknowledged?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DiscoveryFeedbackInput = {
  id?: Maybe<Scalars['ID']>;
  comment: Scalars['String'];
  sentBy: UserInput;
  acknowledged?: Maybe<Scalars['Boolean']>;
};

export type DiscoveryInput = {
  id?: Maybe<Scalars['ID']>;
  criteriaId?: Maybe<Scalars['ID']>;
  annotations?: Maybe<Array<AnnotationInput>>;
  rating?: Maybe<RatingInput>;
  feedback?: Maybe<DiscoveryFeedbackInput>;
  review?: Maybe<Scalars['Boolean']>;
  sseb?: Maybe<Scalars['Boolean']>;
};

export type DiscoveryResponse = EventResponse | Discovery;

export type EvaluationConfiguration = {
  __typename?: 'EvaluationConfiguration';
  discoveryRatings: Scalars['Boolean'];
  criteriaRatings: Scalars['Boolean'];
  overallRatings: Scalars['Boolean'];
};

export type EvaluationTask = {
  __typename?: 'EvaluationTask';
  id: Scalars['ID'];
  assignee: User;
  status: EvaluationTaskStatus;
  taskType: EvaluationTaskType;
  dueAt: Scalars['DateTime'];
  factor: Factor;
  configuration: EvaluationConfiguration;
  name: Scalars['String'];
  description: Scalars['String'];
  rating?: Maybe<RatingValue>;
  discoveries: Array<Discovery>;
  children: Array<EvaluationTask>;
  proposal: Proposal;
  history: Array<EvaluationTaskHistory>;
  updatedAt: Scalars['DateTime'];
};

export type EvaluationTaskHistory = {
  __typename?: 'EvaluationTaskHistory';
  updatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<EvaluationTaskStatus>;
};

export type EvaluationTaskResponse = EventResponse | EvaluationTask;

export enum EvaluationTaskStatus {
  NotReleased = 'NOT_RELEASED',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
  Completed = 'COMPLETED',
  RequiresReviewNotStarted = 'REQUIRES_REVIEW_NOT_STARTED',
  RequiresReviewStarted = 'REQUIRES_REVIEW_STARTED'
}

export enum EvaluationTaskType {
  Evaluation = 'EVALUATION',
  Consensus = 'CONSENSUS'
}

export type EvaluationTaskUpdateStatusInput = {
  status: EvaluationTaskStatus;
  reason: Scalars['String'];
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID'];
};

export type EventResponse = {
  __typename?: 'EventResponse';
  events: Array<Event>;
};

export enum ExpirationStatus {
  Expiring = 'EXPIRING',
  Yearone = 'YEARONE',
  Yeartwo = 'YEARTWO'
}

export type Factor = {
  __typename?: 'Factor';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  criteria: Array<Criteria>;
  discoveryRatingOptions: Array<RatingOption>;
  criteriaRatingOptions: Array<RatingOption>;
  overallRatingOptions: Array<RatingOption>;
  members: Array<SolicitationMember>;
  part?: Maybe<SolicitationPart>;
};

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  key: Scalars['String'];
  name: Scalars['String'];
  transfer: FileTransfer;
  part?: Maybe<SolicitationPart>;
  updatedAt: Scalars['DateTime'];
  user: User;
  tag?: Maybe<FileTag>;
};

export enum FileTag {
  Proposal = 'PROPOSAL',
  Rfp = 'RFP',
  Admin = 'ADMIN',
  Training = 'TRAINING'
}

export type FileTransfer = {
  __typename?: 'FileTransfer';
  presignedUrl: Scalars['String'];
};


export type FileTransferPresignedUrlArgs = {
  type: TransferType;
};

export enum FundingType {
  Om = 'OM',
  Rdte = 'RDTE',
  Proc = 'PROC'
}

export type Initiative = {
  __typename?: 'Initiative';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** lastUpdated is the date calculated from the workbook via the lambda function (given by the KOs) */
  lastUpdated: Scalars['Date'];
  /** dateUpdatedAt is the date we refresh the data (pull the workbook and update the tracker) */
  dateUpdatedAt?: Maybe<Scalars['Date']>;
  score?: Maybe<Scalars['Float']>;
  awardDate?: Maybe<Scalars['Date']>;
  expirationStatus?: Maybe<ExpirationStatus>;
  businessUnit?: Maybe<Scalars['String']>;
  phaseStatus?: Maybe<Scalars['String']>;
  escalationStatus?: Maybe<Scalars['String']>;
  actionValue?: Maybe<Scalars['String']>;
  needByDate?: Maybe<Scalars['Date']>;
  plannedAwardDate?: Maybe<Scalars['Date']>;
  contractPoc?: Maybe<Scalars['String']>;
  pmoPoc?: Maybe<Scalars['String']>;
  actionType?: Maybe<Scalars['String']>;
  fundingType?: Maybe<FundingType>;
  contractingActions: Array<ContractingAction>;
};

export type Mutation = {
  __typename?: 'Mutation';
  upsertRating: RatingResponse;
  upsertDiscovery: DiscoveryResponse;
  deleteDiscovery: DeleteEntityResponse;
  updateEvaluationTaskStatus: EvaluationTaskResponse;
  upsertFile: File;
  deleteFile: DeleteEntityResponse;
  generateEvaluationTasks: Proposal;
  generateReport: File;
};


export type MutationUpsertRatingArgs = {
  communication: CommunicationType;
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
  taskId: Scalars['ID'];
  criteriaId?: Maybe<Scalars['ID']>;
  rating: RatingInput;
};


export type MutationUpsertDiscoveryArgs = {
  communication: CommunicationType;
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
  taskId: Scalars['ID'];
  input?: Maybe<DiscoveryInput>;
};


export type MutationDeleteDiscoveryArgs = {
  communication: CommunicationType;
  id: Scalars['ID'];
  tenantId: Scalars['ID'];
  taskId: Scalars['ID'];
  solicitationId: Scalars['ID'];
};


export type MutationUpdateEvaluationTaskStatusArgs = {
  communication: CommunicationType;
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
  taskId: Scalars['ID'];
  input?: Maybe<EvaluationTaskUpdateStatusInput>;
};


export type MutationUpsertFileArgs = {
  tenantId: Scalars['ID'];
  solicitationId?: Maybe<Scalars['ID']>;
  input?: Maybe<UpsertFileInput>;
};


export type MutationDeleteFileArgs = {
  communication: CommunicationType;
  id: Scalars['ID'];
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
  proposalId?: Maybe<Scalars['ID']>;
};


export type MutationGenerateEvaluationTasksArgs = {
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
  proposalId: Scalars['ID'];
  evaluationDueDate?: Maybe<Scalars['DateTime']>;
  consensusDueDate?: Maybe<Scalars['DateTime']>;
};


export type MutationGenerateReportArgs = {
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
  proposalId: Scalars['ID'];
};

export enum PopType {
  Base = 'BASE',
  Option = 'OPTION',
  Transition = 'TRANSITION',
  Extension = 'EXTENSION'
}

export enum Priority {
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW'
}

export type Proposal = {
  __typename?: 'Proposal';
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Scalars['String'];
  files: Array<File>;
  solicitationId: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  listContracts: Array<Contract>;
  listInitiatives: Array<Initiative>;
  listEvaluationTasksByUser: Array<EvaluationTask>;
  getEvaluationTask?: Maybe<EvaluationTask>;
  getFile?: Maybe<File>;
  getSolicitation?: Maybe<Solicitation>;
  listSolicitationByUser: Array<Solicitation>;
};


export type QueryListContractsArgs = {
  tenantId: Scalars['ID'];
};


export type QueryListInitiativesArgs = {
  tenantId: Scalars['ID'];
};


export type QueryListEvaluationTasksByUserArgs = {
  tenantId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type QueryGetEvaluationTaskArgs = {
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
  taskId: Scalars['ID'];
};


export type QueryGetFileArgs = {
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
  fileId: Scalars['ID'];
};


export type QueryGetSolicitationArgs = {
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
};


export type QueryListSolicitationByUserArgs = {
  tenantId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type RatingInput = {
  option?: Maybe<Scalars['ID']>;
  comment: Scalars['String'];
};

export type RatingOption = {
  __typename?: 'RatingOption';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  backgroundColor: Scalars['String'];
  foregroundColor: Scalars['String'];
};

export type RatingResponse = EventResponse | EvaluationTask | Criteria;

export type RatingValue = {
  __typename?: 'RatingValue';
  id: Scalars['ID'];
  option?: Maybe<Scalars['ID']>;
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Solicitation = {
  __typename?: 'Solicitation';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  tasks: Array<EvaluationTask>;
  proposals: Array<Proposal>;
  members: Array<SolicitationMember>;
  configuration: SolicitationConfiguration;
  factors: Array<Factor>;
  parts: Array<SolicitationPart>;
  files: Array<File>;
};

export type SolicitationConfiguration = {
  __typename?: 'SolicitationConfiguration';
  evaluation: EvaluationConfiguration;
  consensus: EvaluationConfiguration;
};

export type SolicitationMember = {
  __typename?: 'SolicitationMember';
  user: User;
  roles: Array<SolicitationRole>;
};

export type SolicitationPart = {
  __typename?: 'SolicitationPart';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SolicitationPartInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum SolicitationRole {
  Evaluator = 'EVALUATOR',
  ContractSpecialist = 'CONTRACT_SPECIALIST',
  Executive = 'EXECUTIVE'
}

export enum TransferType {
  Upload = 'UPLOAD',
  Download = 'DOWNLOAD'
}

export type UpsertFileInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  proposalId?: Maybe<Scalars['ID']>;
  tag?: Maybe<FileTag>;
  part?: Maybe<SolicitationPartInput>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UserInput = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type GetAcquisitionTrackerPageDataQueryVariables = Exact<{
  tenantId: Scalars['ID'];
}>;


export type GetAcquisitionTrackerPageDataQuery = (
  { __typename?: 'Query' }
  & { listInitiatives: Array<(
    { __typename?: 'Initiative' }
    & Pick<Initiative, 'id' | 'name' | 'score' | 'awardDate' | 'expirationStatus' | 'businessUnit' | 'phaseStatus' | 'escalationStatus' | 'lastUpdated' | 'dateUpdatedAt' | 'actionValue' | 'needByDate' | 'plannedAwardDate' | 'contractPoc' | 'pmoPoc' | 'actionType' | 'fundingType'>
    & { contractingActions: Array<(
      { __typename?: 'ContractingAction' }
      & Pick<ContractingAction, 'id' | 'name' | 'owner' | 'dueDate' | 'completedDate'>
    )> }
  )> }
);

export type GetContractsOverviewPageStateQueryVariables = Exact<{
  tenantId: Scalars['ID'];
}>;


export type GetContractsOverviewPageStateQuery = (
  { __typename?: 'Query' }
  & { listContracts: Array<(
    { __typename?: 'Contract' }
    & Pick<Contract, 'id' | 'externalId' | 'name' | 'businessUnit' | 'dateUpdatedAt' | 'startDate' | 'endDate' | 'ceilingValue' | 'priority' | 'contractingOffice' | 'contractType'>
    & { contractOptions: Array<(
      { __typename?: 'ContractOption' }
      & Pick<ContractOption, 'id' | 'popType' | 'popName' | 'startDate' | 'endDate' | 'value'>
    )>, activeInstruments: Array<(
      { __typename?: 'ActiveInstrument' }
      & Pick<ActiveInstrument, 'id' | 'externalId' | 'name' | 'ceilingValue' | 'businessUnit' | 'contractor' | 'description' | 'contractingPoc' | 'pmoPoc' | 'active' | 'followOnReq' | 'startDate' | 'endDate' | 'cparsDueDate' | 'fundedAmount' | 'dispersedAmount' | 'uloAvailable' | 'ftes' | 'aasFtes' | 'expirationStatus' | 'nextFyFundingReq' | 'fundingType'>
    )> }
  )> }
);

export type GenerateReportMutationVariables = Exact<{
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
  proposalId: Scalars['ID'];
}>;


export type GenerateReportMutation = (
  { __typename?: 'Mutation' }
  & { generateReport: (
    { __typename?: 'File' }
    & { transfer: (
      { __typename?: 'FileTransfer' }
      & Pick<FileTransfer, 'presignedUrl'>
    ) }
  ) }
);

export type UpsertFileMutationVariables = Exact<{
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
  input?: Maybe<UpsertFileInput>;
}>;


export type UpsertFileMutation = (
  { __typename?: 'Mutation' }
  & { upsertFile: (
    { __typename?: 'File' }
    & Pick<File, 'id'>
  ) }
);

export type DeleteFileMutationVariables = Exact<{
  id: Scalars['ID'];
  tenantId: Scalars['ID'];
  proposalId?: Maybe<Scalars['ID']>;
  solicitationId: Scalars['ID'];
}>;


export type DeleteFileMutation = (
  { __typename?: 'Mutation' }
  & { deleteFile: { __typename?: 'EventResponse' } | (
    { __typename?: 'DeletedEntity' }
    & Pick<DeletedEntity, 'id'>
  ) }
);

export type GetDocumentManagerPageDataQueryVariables = Exact<{
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
}>;


export type GetDocumentManagerPageDataQuery = (
  { __typename?: 'Query' }
  & { getSolicitation?: Maybe<(
    { __typename?: 'Solicitation' }
    & Pick<Solicitation, 'id' | 'name'>
    & { files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'tag' | 'updatedAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ) }
    )>, tasks: Array<(
      { __typename?: 'EvaluationTask' }
      & Pick<EvaluationTask, 'id' | 'status' | 'updatedAt'>
      & { proposal: (
        { __typename?: 'Proposal' }
        & Pick<Proposal, 'id' | 'organization'>
      ) }
    )>, members: Array<(
      { __typename?: 'SolicitationMember' }
      & Pick<SolicitationMember, 'roles'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ) }
    )> }
  )> }
);

export type UpsertDiscoveryMutationVariables = Exact<{
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
  taskId: Scalars['ID'];
  input: DiscoveryInput;
}>;


export type UpsertDiscoveryMutation = (
  { __typename?: 'Mutation' }
  & { upsertDiscovery: { __typename?: 'EventResponse' } | (
    { __typename?: 'Discovery' }
    & Pick<Discovery, 'id' | 'createdAt' | 'updatedAt' | 'review' | 'sseb'>
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), annotations: Array<(
      { __typename?: 'Annotation' }
      & Pick<Annotation, 'id' | 'text' | 'startPage' | 'startLine' | 'startIndex' | 'endPage' | 'endLine' | 'endIndex'>
    )>, feedback: Array<(
      { __typename?: 'DiscoveryFeedback' }
      & Pick<DiscoveryFeedback, 'id' | 'comment' | 'acknowledged' | 'createdAt' | 'updatedAt'>
      & { sentBy: (
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName'>
      ) }
    )>, rating: (
      { __typename?: 'RatingValue' }
      & Pick<RatingValue, 'id' | 'option' | 'comment' | 'createdAt' | 'updatedAt'>
    ), criteria: (
      { __typename?: 'Criteria' }
      & Pick<Criteria, 'id' | 'name' | 'description'>
    ) }
  ) }
);

export type DeleteDiscoveryMutationVariables = Exact<{
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
  taskId: Scalars['ID'];
  discoveryId: Scalars['ID'];
}>;


export type DeleteDiscoveryMutation = (
  { __typename?: 'Mutation' }
  & { deleteDiscovery: { __typename?: 'EventResponse' } | (
    { __typename?: 'DeletedEntity' }
    & Pick<DeletedEntity, 'id'>
  ) }
);

export type UpsertRatingMutationVariables = Exact<{
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
  taskId: Scalars['ID'];
  criteriaId?: Maybe<Scalars['ID']>;
  rating: RatingInput;
}>;


export type UpsertRatingMutation = (
  { __typename?: 'Mutation' }
  & { upsertRating: { __typename?: 'EventResponse' } | (
    { __typename?: 'EvaluationTask' }
    & Pick<EvaluationTask, 'id'>
    & { rating?: Maybe<(
      { __typename?: 'RatingValue' }
      & Pick<RatingValue, 'comment' | 'id' | 'option'>
    )> }
  ) | (
    { __typename?: 'Criteria' }
    & Pick<Criteria, 'id'>
    & { rating?: Maybe<(
      { __typename?: 'RatingValue' }
      & Pick<RatingValue, 'comment' | 'id' | 'option'>
    )> }
  ) }
);

export type CompleteTaskMutationVariables = Exact<{
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
  taskId: Scalars['ID'];
  statusInput: EvaluationTaskUpdateStatusInput;
}>;


export type CompleteTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateEvaluationTaskStatus: { __typename?: 'EventResponse' } | (
    { __typename?: 'EvaluationTask' }
    & Pick<EvaluationTask, 'id'>
  ) }
);

export type GetEvaluationPageStateQueryVariables = Exact<{
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
  taskId: Scalars['ID'];
}>;


export type GetEvaluationPageStateQuery = (
  { __typename?: 'Query' }
  & { getEvaluationTask?: Maybe<(
    { __typename?: 'EvaluationTask' }
    & Pick<EvaluationTask, 'id' | 'status' | 'taskType' | 'dueAt' | 'name' | 'description'>
    & { assignee: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    ), factor: (
      { __typename?: 'Factor' }
      & Pick<Factor, 'id' | 'name' | 'description'>
      & { members: Array<(
        { __typename?: 'SolicitationMember' }
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName'>
        ) }
      )>, criteria: Array<(
        { __typename?: 'Criteria' }
        & Pick<Criteria, 'id' | 'name' | 'description'>
        & { rating?: Maybe<(
          { __typename?: 'RatingValue' }
          & Pick<RatingValue, 'id' | 'option' | 'comment' | 'createdAt' | 'updatedAt'>
        )> }
      )>, discoveryRatingOptions: Array<(
        { __typename?: 'RatingOption' }
        & Pick<RatingOption, 'id' | 'name' | 'description' | 'foregroundColor' | 'backgroundColor'>
      )>, criteriaRatingOptions: Array<(
        { __typename?: 'RatingOption' }
        & Pick<RatingOption, 'id' | 'name' | 'description' | 'foregroundColor' | 'backgroundColor'>
      )>, overallRatingOptions: Array<(
        { __typename?: 'RatingOption' }
        & Pick<RatingOption, 'id' | 'name' | 'description' | 'foregroundColor' | 'backgroundColor'>
      )> }
    ), rating?: Maybe<(
      { __typename?: 'RatingValue' }
      & Pick<RatingValue, 'id'>
    )>, discoveries: Array<(
      { __typename?: 'Discovery' }
      & Pick<Discovery, 'id' | 'createdAt' | 'updatedAt' | 'review' | 'sseb'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ), annotations: Array<(
        { __typename?: 'Annotation' }
        & Pick<Annotation, 'id' | 'text' | 'startPage' | 'startLine' | 'startIndex' | 'endPage' | 'endLine' | 'endIndex'>
      )>, feedback: Array<(
        { __typename?: 'DiscoveryFeedback' }
        & Pick<DiscoveryFeedback, 'id' | 'comment' | 'acknowledged' | 'createdAt' | 'updatedAt'>
        & { sentBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName'>
        ) }
      )>, rating: (
        { __typename?: 'RatingValue' }
        & Pick<RatingValue, 'id' | 'option' | 'comment' | 'createdAt' | 'updatedAt'>
      ), criteria: (
        { __typename?: 'Criteria' }
        & Pick<Criteria, 'id' | 'name' | 'description'>
      ) }
    )>, proposal: (
      { __typename?: 'Proposal' }
      & Pick<Proposal, 'id' | 'name' | 'organization'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'name'>
        & { transfer: (
          { __typename?: 'FileTransfer' }
          & Pick<FileTransfer, 'presignedUrl'>
        ) }
      )> }
    ), configuration: (
      { __typename?: 'EvaluationConfiguration' }
      & Pick<EvaluationConfiguration, 'discoveryRatings' | 'criteriaRatings' | 'overallRatings'>
    ) }
  )> }
);

export type GetHomePageDataQueryVariables = Exact<{
  tenantId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type GetHomePageDataQuery = (
  { __typename?: 'Query' }
  & { listEvaluationTasksByUser: Array<(
    { __typename?: 'EvaluationTask' }
    & Pick<EvaluationTask, 'id' | 'name' | 'description' | 'dueAt' | 'status' | 'taskType'>
    & { proposal: (
      { __typename?: 'Proposal' }
      & Pick<Proposal, 'id' | 'solicitationId' | 'name' | 'organization'>
    ) }
  )>, listSolicitationByUser: Array<(
    { __typename?: 'Solicitation' }
    & Pick<Solicitation, 'id' | 'name'>
    & { members: Array<(
      { __typename?: 'SolicitationMember' }
      & Pick<SolicitationMember, 'roles'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
      ) }
    )> }
  )> }
);

export type GetNavigationDataQueryVariables = Exact<{
  tenantId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type GetNavigationDataQuery = (
  { __typename?: 'Query' }
  & { listSolicitationByUser: Array<(
    { __typename?: 'Solicitation' }
    & Pick<Solicitation, 'id' | 'name'>
    & { members: Array<(
      { __typename?: 'SolicitationMember' }
      & Pick<SolicitationMember, 'roles'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
      ) }
    )> }
  )> }
);

export type GetSolicitationPageDataQueryVariables = Exact<{
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type GetSolicitationPageDataQuery = (
  { __typename?: 'Query' }
  & { getSolicitation?: Maybe<(
    { __typename?: 'Solicitation' }
    & Pick<Solicitation, 'id' | 'name'>
    & { files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'name' | 'tag' | 'updatedAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ) }
    )>, members: Array<(
      { __typename?: 'SolicitationMember' }
      & Pick<SolicitationMember, 'roles'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
      ) }
    )> }
  )>, listEvaluationTasksByUser: Array<(
    { __typename?: 'EvaluationTask' }
    & Pick<EvaluationTask, 'id' | 'name' | 'description' | 'dueAt' | 'status' | 'taskType'>
    & { proposal: (
      { __typename?: 'Proposal' }
      & Pick<Proposal, 'id' | 'solicitationId' | 'name' | 'organization'>
    ) }
  )> }
);

export type GetWorkflowPageDataQueryVariables = Exact<{
  tenantId: Scalars['ID'];
  solicitationId: Scalars['ID'];
}>;


export type GetWorkflowPageDataQuery = (
  { __typename?: 'Query' }
  & { getSolicitation?: Maybe<(
    { __typename?: 'Solicitation' }
    & Pick<Solicitation, 'id' | 'name'>
    & { tasks: Array<(
      { __typename?: 'EvaluationTask' }
      & Pick<EvaluationTask, 'id' | 'status' | 'taskType' | 'dueAt' | 'name' | 'updatedAt'>
      & { factor: (
        { __typename?: 'Factor' }
        & Pick<Factor, 'id' | 'name'>
        & { members: Array<(
          { __typename?: 'SolicitationMember' }
          & Pick<SolicitationMember, 'roles'>
          & { user: (
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName'>
          ) }
        )> }
      ), discoveries: Array<(
        { __typename?: 'Discovery' }
        & Pick<Discovery, 'id'>
      )>, proposal: (
        { __typename?: 'Proposal' }
        & Pick<Proposal, 'id' | 'organization'>
      ), history: Array<(
        { __typename?: 'EvaluationTaskHistory' }
        & Pick<EvaluationTaskHistory, 'updatedAt' | 'status'>
      )> }
    )> }
  )> }
);

import { IntrospectionQuery } from 'graphql';
export default {
  "__schema": {
    "queryType": {
      "name": "Query"
    },
    "mutationType": {
      "name": "Mutation"
    },
    "subscriptionType": null,
    "types": [
      {
        "kind": "OBJECT",
        "name": "ActiveInstrument",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "ceilingValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "businessUnit",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "contractor",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "contractingPoc",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "pmoPoc",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "active",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "followOnReq",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "startDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "endDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "cparsDueDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "fundedAmount",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "dispersedAmount",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "uloAvailable",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "ftes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "aasFtes",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "expirationStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "nextFyFundingReq",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "fundingType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Annotation",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "text",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "startPage",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "startLine",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "startIndex",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "endPage",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "endLine",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "endIndex",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Contract",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "externalId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "businessUnit",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "dateUpdatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "startDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "endDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "ceilingValue",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "priority",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "contractingOffice",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "contractType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "contractOptions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ContractOption",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "activeInstruments",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ActiveInstrument",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ContractOption",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "popType",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "popName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "startDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "endDate",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "ContractingAction",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "owner",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "dueDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "completedDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Criteria",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "rating",
            "type": {
              "kind": "OBJECT",
              "name": "RatingValue",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "UNION",
        "name": "DeleteEntityResponse",
        "possibleTypes": [
          {
            "kind": "OBJECT",
            "name": "EventResponse"
          },
          {
            "kind": "OBJECT",
            "name": "DeletedEntity"
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "DeletedEntity",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Discovery",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "author",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "User",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "annotations",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Annotation",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "createdAt",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "rating",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "RatingValue",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "criteria",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Criteria",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "feedback",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "DiscoveryFeedback",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "review",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "sseb",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "DiscoveryFeedback",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "comment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "sentBy",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "User",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "acknowledged",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "createdAt",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "UNION",
        "name": "DiscoveryResponse",
        "possibleTypes": [
          {
            "kind": "OBJECT",
            "name": "EventResponse"
          },
          {
            "kind": "OBJECT",
            "name": "Discovery"
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "EvaluationConfiguration",
        "fields": [
          {
            "name": "discoveryRatings",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "criteriaRatings",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "overallRatings",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "EvaluationTask",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "assignee",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "User",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "taskType",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "dueAt",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "factor",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Factor",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "configuration",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "EvaluationConfiguration",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "rating",
            "type": {
              "kind": "OBJECT",
              "name": "RatingValue",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "discoveries",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Discovery",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "children",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "EvaluationTask",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "proposal",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Proposal",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "history",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "EvaluationTaskHistory",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "EvaluationTaskHistory",
        "fields": [
          {
            "name": "updatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "UNION",
        "name": "EvaluationTaskResponse",
        "possibleTypes": [
          {
            "kind": "OBJECT",
            "name": "EventResponse"
          },
          {
            "kind": "OBJECT",
            "name": "EvaluationTask"
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "Event",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "EventResponse",
        "fields": [
          {
            "name": "events",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Event",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Factor",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "criteria",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Criteria",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "discoveryRatingOptions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "RatingOption",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "criteriaRatingOptions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "RatingOption",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "overallRatingOptions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "RatingOption",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "members",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "SolicitationMember",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "part",
            "type": {
              "kind": "OBJECT",
              "name": "SolicitationPart",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "File",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "key",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "transfer",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "FileTransfer",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "part",
            "type": {
              "kind": "OBJECT",
              "name": "SolicitationPart",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "user",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "User",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "tag",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "FileTransfer",
        "fields": [
          {
            "name": "presignedUrl",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": [
              {
                "name": "type",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Initiative",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "lastUpdated",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "dateUpdatedAt",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "score",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "awardDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "expirationStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "businessUnit",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "phaseStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "escalationStatus",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "actionValue",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "needByDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "plannedAwardDate",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "contractPoc",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "pmoPoc",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "actionType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "fundingType",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "contractingActions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "ContractingAction",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Mutation",
        "fields": [
          {
            "name": "upsertRating",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "UNION",
                "name": "RatingResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "communication",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "tenantId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "solicitationId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "taskId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "criteriaId",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "rating",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "upsertDiscovery",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "UNION",
                "name": "DiscoveryResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "communication",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "tenantId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "solicitationId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "taskId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "input",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "deleteDiscovery",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "UNION",
                "name": "DeleteEntityResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "communication",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "tenantId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "taskId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "solicitationId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "updateEvaluationTaskStatus",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "UNION",
                "name": "EvaluationTaskResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "communication",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "tenantId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "solicitationId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "taskId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "input",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "upsertFile",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "File",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "tenantId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "solicitationId",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "input",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "deleteFile",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "UNION",
                "name": "DeleteEntityResponse",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "communication",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "tenantId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "solicitationId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "proposalId",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "generateEvaluationTasks",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Proposal",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "tenantId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "solicitationId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "proposalId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "evaluationDueDate",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              },
              {
                "name": "consensusDueDate",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "generateReport",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "File",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "tenantId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "solicitationId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "proposalId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Proposal",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "organization",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "files",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "File",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "solicitationId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Query",
        "fields": [
          {
            "name": "listContracts",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Contract",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "tenantId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "listInitiatives",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Initiative",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "tenantId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "listEvaluationTasksByUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "EvaluationTask",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "tenantId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "userId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "getEvaluationTask",
            "type": {
              "kind": "OBJECT",
              "name": "EvaluationTask",
              "ofType": null
            },
            "args": [
              {
                "name": "tenantId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "solicitationId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "taskId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "getFile",
            "type": {
              "kind": "OBJECT",
              "name": "File",
              "ofType": null
            },
            "args": [
              {
                "name": "tenantId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "solicitationId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "fileId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "getSolicitation",
            "type": {
              "kind": "OBJECT",
              "name": "Solicitation",
              "ofType": null
            },
            "args": [
              {
                "name": "tenantId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "solicitationId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "listSolicitationByUser",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Solicitation",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "tenantId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "userId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "RatingOption",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "backgroundColor",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "foregroundColor",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "UNION",
        "name": "RatingResponse",
        "possibleTypes": [
          {
            "kind": "OBJECT",
            "name": "EventResponse"
          },
          {
            "kind": "OBJECT",
            "name": "EvaluationTask"
          },
          {
            "kind": "OBJECT",
            "name": "Criteria"
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "RatingValue",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "option",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "comment",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "createdAt",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "updatedAt",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Solicitation",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "tasks",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "EvaluationTask",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "proposals",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Proposal",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "members",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "SolicitationMember",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "configuration",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "SolicitationConfiguration",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "factors",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Factor",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "parts",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "SolicitationPart",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "files",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "File",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "SolicitationConfiguration",
        "fields": [
          {
            "name": "evaluation",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "EvaluationConfiguration",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "consensus",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "EvaluationConfiguration",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "SolicitationMember",
        "fields": [
          {
            "name": "user",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "User",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "roles",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "SolicitationPart",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "User",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "firstName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "lastName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "SCALAR",
        "name": "Any"
      }
    ],
    "directives": []
  }
} as unknown as IntrospectionQuery;

export const GetAcquisitionTrackerPageDataDocument = gql`
    query getAcquisitionTrackerPageData($tenantId: ID!) {
  listInitiatives(tenantId: $tenantId) {
    id
    name
    score
    awardDate
    expirationStatus
    businessUnit
    phaseStatus
    escalationStatus
    lastUpdated
    dateUpdatedAt
    actionValue
    needByDate
    plannedAwardDate
    contractPoc
    pmoPoc
    actionType
    fundingType
    contractingActions {
      id
      name
      owner
      dueDate
      completedDate
    }
  }
}
    `;

export function useGetAcquisitionTrackerPageDataQuery(options: Omit<Urql.UseQueryArgs<GetAcquisitionTrackerPageDataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetAcquisitionTrackerPageDataQuery>({ query: GetAcquisitionTrackerPageDataDocument, ...options });
};
export const GetContractsOverviewPageStateDocument = gql`
    query getContractsOverviewPageState($tenantId: ID!) {
  listContracts(tenantId: $tenantId) {
    id
    externalId
    name
    businessUnit
    dateUpdatedAt
    startDate
    endDate
    ceilingValue
    priority
    contractingOffice
    contractType
    contractOptions {
      id
      popType
      popName
      startDate
      endDate
      value
    }
    activeInstruments {
      id
      externalId
      name
      ceilingValue
      businessUnit
      contractor
      description
      contractingPoc
      pmoPoc
      active
      followOnReq
      startDate
      endDate
      cparsDueDate
      fundedAmount
      dispersedAmount
      uloAvailable
      ftes
      aasFtes
      expirationStatus
      nextFyFundingReq
      fundingType
    }
  }
}
    `;

export function useGetContractsOverviewPageStateQuery(options: Omit<Urql.UseQueryArgs<GetContractsOverviewPageStateQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetContractsOverviewPageStateQuery>({ query: GetContractsOverviewPageStateDocument, ...options });
};
export const GenerateReportDocument = gql`
    mutation generateReport($tenantId: ID!, $solicitationId: ID!, $proposalId: ID!) {
  generateReport(
    tenantId: $tenantId
    solicitationId: $solicitationId
    proposalId: $proposalId
  ) {
    transfer {
      presignedUrl(type: DOWNLOAD)
    }
  }
}
    `;

export function useGenerateReportMutation() {
  return Urql.useMutation<GenerateReportMutation, GenerateReportMutationVariables>(GenerateReportDocument);
};
export const UpsertFileDocument = gql`
    mutation upsertFile($tenantId: ID!, $solicitationId: ID!, $input: UpsertFileInput) {
  upsertFile(tenantId: $tenantId, solicitationId: $solicitationId, input: $input) {
    ... on File {
      id
    }
  }
}
    `;

export function useUpsertFileMutation() {
  return Urql.useMutation<UpsertFileMutation, UpsertFileMutationVariables>(UpsertFileDocument);
};
export const DeleteFileDocument = gql`
    mutation deleteFile($id: ID!, $tenantId: ID!, $proposalId: ID, $solicitationId: ID!) {
  deleteFile(
    communication: SYNC
    id: $id
    tenantId: $tenantId
    proposalId: $proposalId
    solicitationId: $solicitationId
  ) {
    ... on DeletedEntity {
      id
    }
  }
}
    `;

export function useDeleteFileMutation() {
  return Urql.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument);
};
export const GetDocumentManagerPageDataDocument = gql`
    query getDocumentManagerPageData($tenantId: ID!, $solicitationId: ID!) {
  getSolicitation(tenantId: $tenantId, solicitationId: $solicitationId) {
    id
    name
    files {
      id
      name
      tag
      updatedAt
      user {
        id
        firstName
        lastName
      }
    }
    tasks {
      id
      status
      proposal {
        id
        organization
      }
      updatedAt
    }
    members {
      user {
        id
        firstName
        lastName
      }
      roles
    }
  }
}
    `;

export function useGetDocumentManagerPageDataQuery(options: Omit<Urql.UseQueryArgs<GetDocumentManagerPageDataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetDocumentManagerPageDataQuery>({ query: GetDocumentManagerPageDataDocument, ...options });
};
export const UpsertDiscoveryDocument = gql`
    mutation upsertDiscovery($tenantId: ID!, $solicitationId: ID!, $taskId: ID!, $input: DiscoveryInput!) {
  upsertDiscovery(
    communication: SYNC
    tenantId: $tenantId
    solicitationId: $solicitationId
    taskId: $taskId
    input: $input
  ) {
    ... on Discovery {
      id
      createdAt
      updatedAt
      review
      sseb
      author {
        id
        firstName
        lastName
      }
      annotations {
        id
        text
        startPage
        startLine
        startIndex
        endPage
        endLine
        endIndex
      }
      feedback {
        id
        comment
        sentBy {
          firstName
          lastName
        }
        acknowledged
        createdAt
        updatedAt
      }
      rating {
        id
        option
        comment
        createdAt
        updatedAt
      }
      criteria {
        id
        name
        description
      }
    }
  }
}
    `;

export function useUpsertDiscoveryMutation() {
  return Urql.useMutation<UpsertDiscoveryMutation, UpsertDiscoveryMutationVariables>(UpsertDiscoveryDocument);
};
export const DeleteDiscoveryDocument = gql`
    mutation deleteDiscovery($tenantId: ID!, $solicitationId: ID!, $taskId: ID!, $discoveryId: ID!) {
  deleteDiscovery(
    communication: SYNC
    tenantId: $tenantId
    solicitationId: $solicitationId
    taskId: $taskId
    id: $discoveryId
  ) {
    ... on DeletedEntity {
      id
    }
  }
}
    `;

export function useDeleteDiscoveryMutation() {
  return Urql.useMutation<DeleteDiscoveryMutation, DeleteDiscoveryMutationVariables>(DeleteDiscoveryDocument);
};
export const UpsertRatingDocument = gql`
    mutation upsertRating($tenantId: ID!, $solicitationId: ID!, $taskId: ID!, $criteriaId: ID, $rating: RatingInput!) {
  upsertRating(
    communication: SYNC
    tenantId: $tenantId
    solicitationId: $solicitationId
    taskId: $taskId
    criteriaId: $criteriaId
    rating: $rating
  ) {
    ... on EvaluationTask {
      id
      rating {
        comment
        id
        option
      }
    }
    ... on Criteria {
      id
      rating {
        comment
        id
        option
      }
    }
  }
}
    `;

export function useUpsertRatingMutation() {
  return Urql.useMutation<UpsertRatingMutation, UpsertRatingMutationVariables>(UpsertRatingDocument);
};
export const CompleteTaskDocument = gql`
    mutation completeTask($tenantId: ID!, $solicitationId: ID!, $taskId: ID!, $statusInput: EvaluationTaskUpdateStatusInput!) {
  updateEvaluationTaskStatus(
    communication: SYNC
    tenantId: $tenantId
    solicitationId: $solicitationId
    taskId: $taskId
    input: $statusInput
  ) {
    ... on EvaluationTask {
      id
    }
  }
}
    `;

export function useCompleteTaskMutation() {
  return Urql.useMutation<CompleteTaskMutation, CompleteTaskMutationVariables>(CompleteTaskDocument);
};
export const GetEvaluationPageStateDocument = gql`
    query getEvaluationPageState($tenantId: ID!, $solicitationId: ID!, $taskId: ID!) {
  getEvaluationTask(
    tenantId: $tenantId
    solicitationId: $solicitationId
    taskId: $taskId
  ) {
    id
    assignee {
      id
      firstName
      lastName
    }
    status
    taskType
    dueAt
    name
    description
    factor {
      members {
        user {
          id
          firstName
          lastName
        }
      }
      id
      name
      description
      criteria {
        id
        name
        description
        rating {
          id
          option
          comment
          createdAt
          updatedAt
        }
      }
      discoveryRatingOptions {
        id
        name
        description
        foregroundColor
        backgroundColor
      }
      criteriaRatingOptions {
        id
        name
        description
        foregroundColor
        backgroundColor
      }
      overallRatingOptions {
        id
        name
        description
        foregroundColor
        backgroundColor
      }
    }
    rating {
      id
    }
    discoveries {
      id
      createdAt
      updatedAt
      review
      sseb
      author {
        id
        firstName
        lastName
      }
      annotations {
        id
        text
        startPage
        startLine
        startIndex
        endPage
        endLine
        endIndex
      }
      feedback {
        id
        comment
        sentBy {
          id
          firstName
          lastName
        }
        acknowledged
        createdAt
        updatedAt
      }
      rating {
        id
        option
        comment
        createdAt
        updatedAt
      }
      criteria {
        id
        name
        description
      }
    }
    proposal {
      id
      name
      organization
      files {
        id
        name
        transfer {
          presignedUrl(type: DOWNLOAD)
        }
      }
    }
    configuration {
      discoveryRatings
      criteriaRatings
      overallRatings
    }
  }
}
    `;

export function useGetEvaluationPageStateQuery(options: Omit<Urql.UseQueryArgs<GetEvaluationPageStateQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetEvaluationPageStateQuery>({ query: GetEvaluationPageStateDocument, ...options });
};
export const GetHomePageDataDocument = gql`
    query getHomePageData($tenantId: ID!, $userId: ID!) {
  listEvaluationTasksByUser(tenantId: $tenantId, userId: $userId) {
    id
    name
    description
    dueAt
    status
    taskType
    proposal {
      id
      solicitationId
      name
      organization
    }
  }
  listSolicitationByUser(tenantId: $tenantId, userId: $userId) {
    id
    name
    members {
      user {
        id
      }
      roles
    }
  }
}
    `;

export function useGetHomePageDataQuery(options: Omit<Urql.UseQueryArgs<GetHomePageDataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetHomePageDataQuery>({ query: GetHomePageDataDocument, ...options });
};
export const GetNavigationDataDocument = gql`
    query getNavigationData($tenantId: ID!, $userId: ID!) {
  listSolicitationByUser(tenantId: $tenantId, userId: $userId) {
    id
    name
    members {
      user {
        id
      }
      roles
    }
  }
}
    `;

export function useGetNavigationDataQuery(options: Omit<Urql.UseQueryArgs<GetNavigationDataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetNavigationDataQuery>({ query: GetNavigationDataDocument, ...options });
};
export const GetSolicitationPageDataDocument = gql`
    query getSolicitationPageData($tenantId: ID!, $solicitationId: ID!, $userId: ID!) {
  getSolicitation(tenantId: $tenantId, solicitationId: $solicitationId) {
    id
    name
    files {
      id
      name
      tag
      updatedAt
      user {
        id
        firstName
        lastName
      }
    }
    members {
      user {
        id
      }
      roles
    }
  }
  listEvaluationTasksByUser(tenantId: $tenantId, userId: $userId) {
    id
    name
    description
    dueAt
    status
    taskType
    proposal {
      id
      solicitationId
      name
      organization
    }
  }
}
    `;

export function useGetSolicitationPageDataQuery(options: Omit<Urql.UseQueryArgs<GetSolicitationPageDataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetSolicitationPageDataQuery>({ query: GetSolicitationPageDataDocument, ...options });
};
export const GetWorkflowPageDataDocument = gql`
    query getWorkflowPageData($tenantId: ID!, $solicitationId: ID!) {
  getSolicitation(tenantId: $tenantId, solicitationId: $solicitationId) {
    id
    name
    tasks {
      id
      status
      taskType
      dueAt
      name
      factor {
        id
        name
        members {
          user {
            id
            firstName
            lastName
          }
          roles
        }
      }
      discoveries {
        id
      }
      proposal {
        id
        organization
      }
      history {
        updatedAt
        status
      }
      updatedAt
    }
  }
}
    `;

export function useGetWorkflowPageDataQuery(options: Omit<Urql.UseQueryArgs<GetWorkflowPageDataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetWorkflowPageDataQuery>({ query: GetWorkflowPageDataDocument, ...options });
};