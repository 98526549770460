import { Box, Typography } from '@mui/material'
import { BasicAccordian } from 'components/Accordian'
import Acknowledgement from 'components/Acknowledgement'
import { BidscaleButton } from 'components/Buttons/BidscaleButton'
import RatingSelection from 'components/form/RatingSelection'
import { MessageAlert } from 'components/MessageAlert'
import MultiLineTextField from 'components/MultiLineTextField'
import RatingDefinitions from 'components/RatingDefinitions'
import { RatingOption } from 'generated/graphql'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { theme } from 'theme'

export interface Props {
  withRating: boolean
  formHook: UseFormReturn<FieldValues>
  ratings: Array<RatingOption>
  proposalName?: string
  onSave: () => void
  onCancel: () => void
}

export const RecommendationDrawerContent = ({
  withRating,
  formHook,
  ratings,
  proposalName,
  onSave,
  onCancel,
}: Props): JSX.Element => {
  const { watch } = formHook
  const isChecked = watch('checkbox')

  const hasErrors = !(
    Object.keys(formHook.formState.errors).length === 0 &&
    formHook.formState.errors.constructor === Object
  )

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box id={'recommendation-top-portion'}>
        <Box sx={{ mt: 3 }}>
          <Typography variant="body1">{proposalName}</Typography>
        </Box>
        {withRating && (
          <>
            <Box id={'recommendation-definitions'} mt={4}>
              <BasicAccordian title={'Definitions'}>
                <Box sx={{ px: 2, pt: 1 }}>
                  <RatingDefinitions data={ratings} />
                </Box>
              </BasicAccordian>
            </Box>
          </>
        )}
        {hasErrors && (
          <Box sx={{ pt: 5, pb: 2 }}>
            <MessageAlert variant="error" />
          </Box>
        )}
        {withRating && (
          <Box id={'recommendation-rate-discovery'} mt={1}>
            <RatingSelection
              defaultOption={'Select Rating'}
              useFormHook={formHook}
              label="Rate This Proposal"
              items={ratings}
              itemColor={'backgroundColor'}
              itemDefinition="description"
              rules={{ required: true }}
              hideErrorMessage={true}
            />
          </Box>
        )}
        <Box id={'overall-recommendation-comment'} mt={4}>
          <MultiLineTextField
            rows={5}
            variant={'outlined'}
            title={'Overall Recommendation'}
            name={'Overall Recommendation'}
            placeholder={'Write a comment'}
            useFormHook={formHook}
            rules={{ required: true }}
          />
        </Box>
        <Box id={'recommendation-acknowledgement'} mt={5}>
          <Acknowledgement
            control={formHook.control}
            title="Acknowledgement"
            message="Submitting this evaluation will finalize the evaluation and return you to the homepage"
            name="checkbox"
            rules={{ required: true }}
          />
        </Box>
      </Box>

      <Box
        id={'recommendation-buttom-portion'}
        sx={{
          position: 'sticky',
          bottom: 0,
          bgcolor: 'background.paper',
          py: 1,
          zIndex: 33,
        }}
      >
        <Box
          id={'recommendation-action-buttons'}
          sx={{
            my: 3,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <BidscaleButton
            id={'recommendation-cancel-button'}
            buttonVariant="cancel"
            sx={{ width: theme.spacing(29), mr: 3 }}
            onClick={onCancel}
          >
            Cancel
          </BidscaleButton>
          <BidscaleButton
            id={'recommendation-save-button'}
            buttonVariant="execute"
            sx={{ width: theme.spacing(29) }}
            onClick={onSave}
            disabled={!isChecked}
          >
            Confirm
          </BidscaleButton>
        </Box>
      </Box>
    </Box>
  )
}
