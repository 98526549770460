import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { alpha, Box, IconButton, Typography } from '@mui/material'
import { ActionMenuKebab } from 'components/ActionMenuKebab'
import { CancelButton, DeleteButton } from 'components/Buttons/BidscaleButton'
import DataTable, { Props as DataTableProps } from 'components/DataTable'
import {
  ChipRenderer,
  TextRenderer,
} from 'components/DataTable/GenericRenderers'
import SelectionBox from 'components/form/SelectionBox'
import SingleLineInput from 'components/form/SingleLineInput'
import { MessageAlert } from 'components/MessageAlert'
import { FileTag, Maybe } from 'generated/graphql'
import { useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { theme } from 'theme'

import { DocsTable } from '../constants'
import { DocumentsTableRow } from '../state/useDocumentManagerState'

interface Props {
  documentsTableData: DocumentsTableRow[]
  deleteFile: (fileId: string, proposalId?: string) => Promise<boolean>
  editFile: (id: string, name: string, tag?: Maybe<FileTag>) => Promise<boolean>
}

const DocumentsTable = ({
  documentsTableData,
  deleteFile,
  editFile,
}: Props): JSX.Element => {
  const [deleteId, setDeleteId] = useState<string | null>(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [editingDocumentId, setEditingDocumentId] = useState<string | null>(
    null
  )
  const formHook = useForm({
    defaultValues: {
      'file-id': '',
      'file-name': '',
      'file-tag': '',
    } as FieldValues,
    shouldFocusError: false,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const {
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = formHook

  const hasErrors = !(
    Object.keys(errors).length === 0 && errors.constructor === Object
  )

  const handleEdit = (data: DocumentsTableRow) => {
    setValue('file-id', data.id)
    setValue('file-name', data.name)
    setValue('file-tag', data.tag)
    setEditingDocumentId(data.id)
  }

  const handleSubmitEdit = async (data: Record<string, string>) => {
    setIsSubmitting(true)
    const editedSuccessfully = await editFile(
      data['file-id'],
      data['file-name'],
      data['file-tag'] === '' ? null : (data['file-tag'] as FileTag)
    )
    if (editedSuccessfully) {
      setEditingDocumentId(null)
      reset()
    }
    setIsSubmitting(false)
  }

  const handleCancelEdit = () => {
    setEditingDocumentId(null)
    reset()
  }

  const handleDeleteFile = async (id: string) => {
    setIsDeleting(true)
    await deleteFile(id)
    setDeleteId(null)
    setIsDeleting(false)
  }

  const errorStyleRenderer = (data: DocumentsTableRow) => {
    const getColor = () => {
      return deleteId === data.id
        ? `${alpha(theme.palette.error.light, 0.15)}`
        : editingDocumentId === data.id
        ? `${alpha(theme.palette.primary.main, 0.1)}`
        : 'none'
    }
    return {
      backgroundColor: getColor(),
    }
  }

  const cellStyleRenderer = (data: DocumentsTableRow) => {
    return {
      ...errorStyleRenderer(data),
      minWidth: '165px',
      width: '14%',
    }
  }

  const TextFieldRenderer = (data: DocumentsTableRow): JSX.Element => {
    return editingDocumentId === data.id ? (
      <Box display="flex">
        <SingleLineInput
          label={'file-name'}
          hideLabel
          useFormHook={formHook}
          sx={{
            input: styles.inputSx,
          }}
          inputProps={{
            style: styles.inputProps,
          }}
          rules={{ required: 'This field is required' }}
          disabled={isSubmitting}
        />
      </Box>
    ) : (
      <Typography variant="body1">{data.name}</Typography>
    )
  }

  const TagRenderer = (data: DocumentsTableRow): JSX.Element => {
    return (
      <>
        {deleteId === data.id ? (
          <CancelButton onClick={() => setDeleteId(null)}>Cancel</CancelButton>
        ) : editingDocumentId === data.id ? (
          <Box display="flex">
            <SelectionBox
              label={'file-tag'}
              items={Object.values(FileTag).map((value) => ({
                id: value,
                name: value,
              }))}
              useFormHook={formHook}
              defaultOption="--"
              defaultValue={''}
              hideLabel
              disabled={isSubmitting}
            />
          </Box>
        ) : (
          <Typography variant="body1">{data.tag ?? '--'}</Typography>
        )}
      </>
    )
  }

  const actionMenuRenderer = (data: DocumentsTableRow): JSX.Element => {
    return (
      <>
        {deleteId === data.id ? (
          <DeleteButton
            onClick={() => handleDeleteFile(data.id)}
            activityIndicator={isDeleting}
          >
            Delete
          </DeleteButton>
        ) : editingDocumentId === data.id ? (
          <Box display="flex">
            <IconButton
              sx={{ mr: 1 }}
              type="submit"
              onClick={handleSubmit(handleSubmitEdit)}
              disabled={isSubmitting}
            >
              <CheckIcon
                color="success"
                sx={{
                  opacity: isSubmitting ? '20%' : '100%',
                }}
              />
            </IconButton>
            <IconButton onClick={handleCancelEdit} disabled={isSubmitting}>
              <CloseIcon
                color="error"
                sx={{
                  opacity: isSubmitting ? '20%' : '100%',
                }}
              />
            </IconButton>
          </Box>
        ) : (
          <ActionMenuKebab
            items={[
              { itemName: 'Edit', onClick: () => handleEdit(data) },
              { itemName: 'Delete', onClick: () => setDeleteId(data.id) },
            ]}
          />
        )}
      </>
    )
  }
  const tableConfig: DataTableProps<DocumentsTableRow> = {
    columns: [
      {
        name: DocsTable.NAME,
        renderer: TextFieldRenderer,
        sort: (item) => item.name.toLowerCase(),
        key: 'name',
        bodySx: errorStyleRenderer,
      },
      {
        name: DocsTable.TYPE,
        renderer: ChipRenderer,
        sort: (item) => item.type.toLowerCase(),
        key: 'type',
        bodySx: errorStyleRenderer,
      },
      {
        name: DocsTable.UPLOADED_BY,
        renderer: TextRenderer,
        sort: (item) => item.user.toLowerCase(),
        key: 'user',
        bodySx: errorStyleRenderer,
      },
      {
        name: DocsTable.TAGS,
        renderer: TagRenderer,
        sort: (item) => (item.tag ? item.tag : '--'),
        bodySx: cellStyleRenderer,
      },
      {
        name: DocsTable.ACTIONS,
        renderer: actionMenuRenderer,
        bodySx: cellStyleRenderer,
      },
    ],
    data: documentsTableData,
    rowsPerPage: 5,
  }

  return (
    <Box width={1}>
      {hasErrors && (
        <Box pb={3}>
          <MessageAlert variant="error" />
        </Box>
      )}
      <Box>
        <DataTable {...tableConfig} />
      </Box>
    </Box>
  )
}

export { DocumentsTable }

const styles = {
  inputSx: {
    backgroundColor: 'info.light',
  },
  inputProps: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.body2.fontSize,
    fontStyle: theme.typography.body2.fontStyle,
  },
}
