import { Box } from '@mui/material'
import { no_select } from 'constants/styles'
import { useEffect, useState } from 'react'

import { HighlightData } from './WrappedPage'

interface Props {
  highlightData: HighlightData
  pageNumber: number
  activeAnnotation?: string
  render: boolean
  id: string
  openViewDiscoveriesDrawer: (discoveryIds: string[]) => void
}

const Highlight = ({
  highlightData,
  pageNumber,
  activeAnnotation,
  render,
  openViewDiscoveriesDrawer,
}: Props): JSX.Element => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(highlightData.annotationIds.some((id) => activeAnnotation === id))
  }, [activeAnnotation, setActive, highlightData])

  const handleClick = () => {
    openViewDiscoveriesDrawer(highlightData.discoveryIds)
  }
  return (
    <>
      {render && (
        <Box
          id={highlightData.annotationIds.join('-')}
          className={`renderedHighlight${pageNumber}`}
          onClick={handleClick}
          sx={{
            position: 'absolute',
            top: highlightData.top,
            left: highlightData.left,
            backgroundColor: active ? 'primary.main' : 'secondary.main',
            opacity: 0.8,
            mixBlendMode: 'multiply',
            height: highlightData.height,
            width: highlightData.width,
            zIndex: 100,
            cursor: 'pointer',
            ...no_select,
          }}
          /*
        Placed a ‘noSelect’ on previously existing highlight to 
        prevent crash should user end a selection with their mouse pointer on
        pre-existing highlight for new highlight.
         */
        />
      )}
    </>
  )
}

export default Highlight
