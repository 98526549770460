import { DiscoveryInput, EvaluationTask, Scalars } from 'generated/graphql'

import { SummaryDrawerContent } from './SummaryDrawerContent'

export interface Props {
  task: EvaluationTask
  editDiscovery: (discoveryInput: DiscoveryInput) => Promise<boolean>
  deleteDiscovery: (discoveryId: Scalars['ID']) => Promise<boolean>
}

const SummaryDrawer = ({
  task,
  editDiscovery,
  deleteDiscovery,
}: Props): JSX.Element => {
  return (
    <SummaryDrawerContent
      task={task}
      onEditDiscovery={editDiscovery}
      onDeleteDiscovery={deleteDiscovery}
    />
  )
}

export { SummaryDrawer }
