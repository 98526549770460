import { Box } from '@mui/material'
import {
  ActivityFeedView,
  solicitationActivityFeeds,
} from 'components/ActivityFeed'
import TaskAccordion from 'components/TaskAccordion'
import { EvaluationTask, EvaluationTaskStatus } from 'generated/graphql'
import { ActivityFeed } from 'pages/Home/state'
import React from 'react'

import ActionsMessage from './ActionsMessage'

interface Props extends ActivityFeed {
  tasks?: EvaluationTask[]
}

function SolicitationDashboard({ tasks }: Props): JSX.Element {
  const tasksDue = tasks?.filter((task) => task.status !== 'COMPLETED')
  const completedTasks = tasks?.filter((task) => task.status === 'COMPLETED')
  return (
    <Box display="flex" pt={1} width="100%">
      <Box display="flex" flexDirection="column" width="60%" pr={4}>
        <ActionsMessage tasks={tasksDue} />
        <TaskAccordion
          accordions={[
            {
              accordionTitle: 'Tasks Due',
              tasks: tasksDue?.map((task) => ({
                taskId: task.id,
                solicitationId: task.proposal.solicitationId,
                taskName: task.name,
                linkTo: `/solicitations/${encodeURIComponent(
                  task.proposal.solicitationId
                )}/evaluations/${encodeURIComponent(task.id)}`,
                newTab: true,
                taskDescription: task.description,
                taskDueOrCompletedDate: task.dueAt,
                isComplete: task.status === EvaluationTaskStatus.Completed,
                projectName: task.proposal.name,
                offeror: task.proposal.organization,
              })),
              emptyMessage: 'You have no tasks due at this time',
            },
            {
              accordionTitle: 'Completed Tasks',
              tasks: completedTasks?.map((task) => ({
                taskId: task.id,
                solicitationId: task.proposal.solicitationId,
                taskName: task.name,
                handleClick: () => alert('ayeee'),
                taskDescription: task.description,
                taskDueOrCompletedDate: task.dueAt,
                isComplete: task.status === EvaluationTaskStatus.Completed,
                projectName: task.proposal.name,
                offeror: task.proposal.organization,
              })),
              emptyMessage: 'You have no completed tasks at this time',
            },
          ]}
        />
      </Box>
      <Box width="40%" pl={4}>
        <ActivityFeedView {...solicitationActivityFeeds} />
      </Box>
    </Box>
  )
}

export default SolicitationDashboard
