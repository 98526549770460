import { Box, Typography } from '@mui/material'
import Chip from '@mui/material/Chip'
import { Tag, Variant } from 'components/Tag'
import { format } from 'date-fns'

import { ColumnConfiguration } from '..'

export const TextRenderer = <T,>(
  data: T,
  column: ColumnConfiguration<T>
): JSX.Element => {
  return (
    <Box>
      {column.key && (
        <Typography variant="body2" noWrap={false}>
          {data[column.key] ? data[column.key] : '--'}
        </Typography>
      )}
    </Box>
  )
}

export const DateTimeRenderer = <T,>(
  data: T,
  column: ColumnConfiguration<T>
): JSX.Element => {
  return (
    <Box>
      {column.key && (
        <Typography variant="body2">
          {data[column.key]
            ? format(
                new Date(data[column.key] as unknown as string),
                'yyyy-MM-dd HH:mm'
              )
            : '--'}
        </Typography>
      )}
    </Box>
  )
}

export const DateRenderer = <T,>(
  data: T,
  column: ColumnConfiguration<T>
): JSX.Element => {
  return (
    <Box>
      {column.key && (
        <Typography variant="body2">
          {data[column.key]
            ? format(
                new Date(data[column.key] as unknown as string),
                'yyyy-MM-dd'
              )
            : '--'}
        </Typography>
      )}
    </Box>
  )
}

export const ChipRenderer = <T,>(
  data: T,
  column: ColumnConfiguration<T>
): JSX.Element => {
  return <Box>{column.key && <Chip label={data[column.key]}></Chip>}</Box>
}

export const TagRenderer = <T,>(
  data: T,
  column: ColumnConfiguration<T>
): JSX.Element => {
  return (
    <Box>
      {column.key && <Tag variant={data[column.key] as unknown as Variant} />}
    </Box>
  )
}
