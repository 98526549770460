/* eslint-disable */
/**
 *  WARNING: DO NOT EDIT. This file is autogenerated in automated pipelines.
 * This file is generated by generate-bdsc-exports.sh
 **/

const awsmobile = { "aws_user_pools_id": "us-east-1_EKyaTFGg7", "aws_user_pools_web_client_id": "19hk2dsl827kc5lm15ji13p4pl", "aws_appsync_graphqlEndpoint": "https://pskdf7q3h2.execute-api.us-east-1.amazonaws.com/select/graphql", "bdsc_branch_id": "internal", "aws_cognito_region": "us-east-1" };


export default awsmobile;
