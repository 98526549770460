import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Typography } from '@mui/material'
import BidscaleLogo from 'assets/Bidscale_Logomark_WhiteTealBackground_2022_V1.png'
import { ExecuteButton } from 'components/Buttons/BidscaleButton'
import { theme } from 'theme'

export interface SummaryDrawerHeaderProps {
  closeDrawer: () => void
  submitReview: () => void
}

export const SummaryDrawerHeader = ({
  closeDrawer,
  submitReview,
}: SummaryDrawerHeaderProps): JSX.Element => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      id="summary-top-bar"
      mr={2}
    >
      <Box display="flex" alignItems="center">
        <Box width={48} height={48}>
          <img
            src={BidscaleLogo}
            alt="Bidscale Logo"
            style={{ marginRight: '12px', width: '100%' }}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Box
            onClick={closeDrawer}
            sx={{
              pl: '12px',
              color: 'primary.main',
              display: 'flex',
              alignItems: 'center',
              '&:hover': { color: 'primary.dark', cursor: 'pointer' },
            }}
          >
            <ArrowBackIcon color="inherit" fontSize="small" />
            <Typography color="inherit" variant="body1" pl={1}>
              Evaluation
            </Typography>
          </Box>
          <Typography color="primary.main" variant="body1" pl={1}>
            /
          </Typography>
          <Typography color="text.secondary" variant="body1" pl={1}>
            Summary
          </Typography>
        </Box>
      </Box>

      <Box>
        <ExecuteButton
          sx={{
            width: 1,
            height: theme.spacing(6),
            px: 3,
          }}
          onClick={submitReview}
        >
          SUBMIT REVIEW
        </ExecuteButton>
      </Box>
    </Box>
  )
}
