import { FileTypes } from 'constants/enumerations'
import { useEffect } from 'react'
import { useFilePicker } from 'use-file-picker'
import { FileContent } from 'use-file-picker/dist/interfaces'

import { BidscaleButton } from '../BidscaleButton'

export interface Props {
  onFilesSelected: (fileContent: FileContent[]) => void
}

const FilePickerButton = (props: Props): JSX.Element => {
  const [openFileSelector, { filesContent, clear }] = useFilePicker({
    accept: Object.keys(FileTypes).map((type) => '.' + type),
    readAs: 'ArrayBuffer',
  })

  useEffect(() => {
    if (filesContent.length > 0 && props.onFilesSelected) {
      props.onFilesSelected(filesContent)
      clear()
    }
  }, [filesContent, clear, props])

  return (
    <BidscaleButton buttonVariant="execute" onClick={() => openFileSelector()}>
      Upload File
    </BidscaleButton>
  )
}

export { FilePickerButton }
