import { useParams } from 'react-router-dom'
import { FileContent } from 'use-file-picker/dist/interfaces'

import DocumentManagerPageSkeleton from './DocumentManagerPageSkeleton'
import { Page } from './Page'
import {
  useDocumentManagerState,
  useDocumentManagerTransform,
} from './state/useDocumentManagerState'

const DocumentManager = (): JSX.Element => {
  const { solicitationId } = useParams<{ solicitationId: string }>()

  const { solicitation, generateReport, deleteFile, editFile } =
    useDocumentManagerState(decodeURIComponent(solicitationId || ''))

  const transformedProps = useDocumentManagerTransform(solicitation)
  const onFilesSelected = (_fileContent: FileContent[]) => {
    alert('TODO: THIS FUNCTION NEEDS TO BE IMPLEMENTED')
  }

  return solicitation ? (
    <Page
      {...transformedProps}
      generateReport={generateReport}
      deleteFile={deleteFile}
      editFile={editFile}
      onFilesSelected={onFilesSelected}
    />
  ) : (
    <DocumentManagerPageSkeleton />
  )
}

export { DocumentManager }
