import useResizeObserver from '@react-hook/resize-observer'
import { RefObject, useLayoutEffect, useState } from 'react'

const useElementSize = (
  target: RefObject<HTMLDivElement> | null,
  onResize?: (rect: DOMRect) => void
): DOMRect | undefined => {
  const [size, setSize] = useState<DOMRect | undefined>(undefined)
  useLayoutEffect(() => {
    setSize(target?.current?.getBoundingClientRect())
  }, [target])

  useResizeObserver(target, (entry) => {
    setSize(entry.contentRect)
    onResize?.(target?.current?.getBoundingClientRect() || entry.contentRect)
  })
  return size
}

export { useElementSize }
