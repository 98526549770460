import Auth, { CognitoUser } from '@aws-amplify/auth'
import { ResetPasswordForm } from 'pages/Login/Authentication/ForgotPassword/ResetPasswordForm'
import LoginForm from 'pages/Login/Authentication/LoginForm'
import { useContext, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { UserContext } from './User/providers/UserProvider'

enum Forms {
  LOGIN,
  RESET_PASSWORD,
}

interface LocationState {
  from: {
    pathname: string
  }
}

const Login = (): JSX.Element => {
  const [formType, setFormType] = useState(Forms.LOGIN)
  const [authUser, setAuthUser] = useState({} as CognitoUser)
  const [isLoading, setLoading] = useState(false)
  const [showResetError, setShowResetError] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { setUser } = useContext(UserContext)

  const { from } = (location.state as LocationState) || {
    from: { pathname: '/' },
  }

  const loginFormHook = useForm<FieldValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const resetPasswordFormHook = useForm<FieldValues>({
    defaultValues: { newpassword: '', verifypassword: '' },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const signIn = async () => {
    if (!isLoading) {
      const values = loginFormHook.getValues()
      setLoading(true)
      try {
        const user = await Auth.signIn(values.email, values.password)
        if (
          user.challengeName &&
          user.challengeName === 'NEW_PASSWORD_REQUIRED'
        ) {
          setFormType(Forms.RESET_PASSWORD)
          setAuthUser(user)
        } else {
          setUser()
          navigate(from.pathname === '/login' ? '/' : from.pathname, {
            replace: true,
          })
        }
      } catch (err) {
        loginFormHook.setValue('email', '', { shouldValidate: false })
        loginFormHook.setValue('password', '', { shouldValidate: false })
        loginFormHook.setError('email', {})
        loginFormHook.setError('password', {})
      }
      setLoading(false)
    }
  }

  const resetPassword = async (data: FieldValues) => {
    try {
      await Auth.completeNewPassword(authUser, data.newpassword)
      await Auth.signOut()
      setFormType(Forms.LOGIN)
    } catch (err) {
      setShowResetError(true)
      resetPasswordFormHook.reset({ newpassword: '', verifypassword: '' })
    }
  }

  return (
    <>
      {formType === Forms.LOGIN && (
        <LoginForm handleSubmission={signIn} useFormHook={loginFormHook} />
      )}

      {formType === Forms.RESET_PASSWORD && (
        <ResetPasswordForm
          handleSubmission={resetPassword}
          useFormHook={resetPasswordFormHook}
          showResetError={showResetError}
        />
      )}
    </>
  )
}

export default Login
