import { Cache as UrqlCache, ResolveInfo } from '@urql/exchange-graphcache'
import {
  DeletedEntity,
  DeleteDiscoveryMutation,
  Discovery,
  DiscoveryInput,
  EvaluationTask,
  EvaluationTaskStatus,
  EvaluationTaskUpdateStatusInput,
  GetEvaluationPageStateDocument,
  GetEvaluationPageStateQuery,
  MutationDeleteDiscoveryArgs,
  MutationUpsertDiscoveryArgs,
  RatingInput,
  Scalars,
  UpsertDiscoveryMutation,
  useCompleteTaskMutation,
  useDeleteDiscoveryMutation,
  useGetEvaluationPageStateQuery,
  useUpsertDiscoveryMutation,
  useUpsertRatingMutation,
} from 'generated/graphql'
import { map, omit, remove } from 'lodash'
import { useSnackbar } from 'notistack'
import { UserContext } from 'pages/Login/User/providers/UserProvider'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export interface EvaluationError {
  type: 'edit-discovery' | 'delete-discovery' | 'add-discovery'
  message: string
}

export interface EvaluationPageState {
  evaluationTask?: EvaluationTask

  addDiscovery: (
    discoveryInput: DiscoveryInput
  ) => Promise<Discovery | undefined>
  editDiscovery: (discoveryInput: DiscoveryInput) => Promise<boolean>
  deleteDiscovery: (discoveryId: Scalars['ID']) => Promise<boolean>
  upsertRating: (inputs: {
    rating: RatingInput
    criteriaId?: Scalars['ID']
  }) => void
  completeTask: () => void
  startTask: () => void
  isFetching: boolean
  error?: EvaluationError
  clearError: () => void
}

export function useEvaluationPageState({
  solicitationId,
  evaluationTaskId,
}: {
  solicitationId: string
  evaluationTaskId: string
}): EvaluationPageState {
  const userContext = useContext(UserContext)
  const userId = userContext.user.userId
  const tenantId = userContext.user.tenant.id
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [evaluationTask, setTask] = useState<EvaluationTask>()

  const [_upsertDiscoveryResult, upsertDiscoveryMutation] =
    useUpsertDiscoveryMutation()
  const [_deleteDiscoveryResult, deleteDiscoveryMutation] =
    useDeleteDiscoveryMutation()
  const [upsertRatingResult, upsertRatingMutation] = useUpsertRatingMutation()
  const [completeTaskResult, completeTaskMutation] = useCompleteTaskMutation()
  const [error, setError] = useState<EvaluationError>()

  const [query] = useGetEvaluationPageStateQuery({
    variables: {
      tenantId: tenantId,
      solicitationId: solicitationId,
      taskId: evaluationTaskId,
    },
    pause: userId === '' || tenantId === '' || evaluationTaskId === '',
  })

  useEffect(() => {
    if (query?.data) {
      setTask(query?.data.getEvaluationTask as EvaluationTask)
    }
  }, [query, setTask])

  //TODO: Move dispatch to subscriptions when ready
  const addDiscovery = async (discoveryInput: DiscoveryInput) => {
    const upsertDiscoveryResult = await upsertDiscoveryMutation({
      tenantId: tenantId,
      solicitationId: solicitationId,
      taskId: evaluationTaskId,
      input: discoveryInput,
    })

    if (upsertDiscoveryResult.error) {
      setError({
        type: 'add-discovery',
        message: 'There was a problem while saving. Please try again.',
      })
    } else {
      enqueueSnackbar(`New discovery has been added.`)
      return upsertDiscoveryResult.data?.upsertDiscovery as Discovery
    }
  }

  //TODO: Move dispatch to subscriptions when ready
  const editDiscovery = async (discoveryInput: DiscoveryInput) => {
    clearError()
    discoveryInput.annotations = map(discoveryInput.annotations, (annotation) =>
      omit(annotation, '__typename')
    )

    const upsertDiscoveryResult = await upsertDiscoveryMutation({
      tenantId: tenantId,
      solicitationId: solicitationId,
      taskId: evaluationTaskId,
      input: discoveryInput,
    })

    if (upsertDiscoveryResult.error) {
      setError({
        type: 'edit-discovery',
        message: 'There was a problem while saving. Please try again.',
      })
      return false
    } else {
      enqueueSnackbar(`Discovery has been updated.`)
      return true
    }
  }

  const deleteDiscovery = async (discoveryId: Scalars['ID']) => {
    clearError()
    const deleteDiscoveryResult = await deleteDiscoveryMutation({
      tenantId: tenantId,
      solicitationId: solicitationId,
      taskId: evaluationTaskId,
      discoveryId: discoveryId,
    })

    if (deleteDiscoveryResult.error) {
      setError({
        type: 'delete-discovery',
        message: 'There was a problem while saving. Please try again.',
      })
      return false
    } else {
      enqueueSnackbar(`Discovery has been deleted.`)
      return true
    }
  }

  const upsertRating = async ({
    rating,
    criteriaId,
  }: {
    rating: RatingInput
    criteriaId?: Scalars['ID']
  }) => {
    await upsertRatingMutation({
      tenantId: tenantId,
      solicitationId: solicitationId,
      taskId: evaluationTaskId,
      criteriaId: criteriaId,
      rating: rating,
    })
    if (upsertRatingResult.error) {
      enqueueSnackbar(`Failed to save rating.`, {
        variant: 'error',
      })
    } else {
      enqueueSnackbar(`Rating added.`)
    }
  }

  const completeTask = async () => {
    const statusInput: EvaluationTaskUpdateStatusInput = {
      reason: 'Evaluation task completed using Bidscale Select.',
      status: EvaluationTaskStatus.Completed,
    }

    await completeTaskMutation({
      tenantId: tenantId,
      taskId: evaluationTaskId,
      solicitationId: solicitationId,
      statusInput: statusInput,
    })
    if (completeTaskResult.error) {
      enqueueSnackbar(`Failed to complete task.`, {
        variant: 'error',
      })
    } else {
      enqueueSnackbar(`Task has been completed.`)
      navigate('/')
    }
  }

  const startTask = async () => {
    const statusInput: EvaluationTaskUpdateStatusInput = {
      reason: 'Evaluation task has been started using Bidscale Select.',
      status: EvaluationTaskStatus.Started,
    }
    // TODO: consider renaming completeTaskMutation to updateTaskMutation.
    await completeTaskMutation({
      tenantId: tenantId,
      taskId: evaluationTaskId,
      solicitationId: solicitationId,
      statusInput: statusInput,
    })
    if (completeTaskResult.error) {
      // eslint-disable-next-line no-console
      console.error('Failed to start the task.')
    }
  }

  const clearError = () => {
    setError(undefined)
  }

  return {
    evaluationTask,
    addDiscovery,
    editDiscovery,
    deleteDiscovery,
    upsertRating,
    completeTask,
    startTask,
    error,
    isFetching: query.fetching,
    clearError,
  }
}

export function upsertDiscoveryCache(
  result: UpsertDiscoveryMutation,
  args: MutationUpsertDiscoveryArgs,
  cache: UrqlCache,
  _info: ResolveInfo
): void {
  const isCreate = !args.input?.id
  const query = GetEvaluationPageStateDocument
  cache.updateQuery(
    {
      query,
      variables: {
        tenantId: args.tenantId,
        solicitationId: args.solicitationId,
        taskId: args.taskId,
      },
    },
    (data: GetEvaluationPageStateQuery | null) => {
      if (isCreate && args.communication === 'SYNC') {
        result?.upsertDiscovery &&
          data?.getEvaluationTask?.discoveries.push(
            result?.upsertDiscovery as Discovery
          )
        return data
      }

      return data
    }
  )
}

export function deleteDiscoveryCache(
  result: DeleteDiscoveryMutation,
  args: MutationDeleteDiscoveryArgs,
  cache: UrqlCache,
  _info: ResolveInfo
): void {
  const query = GetEvaluationPageStateDocument
  cache.updateQuery(
    {
      query,
      variables: {
        tenantId: args.tenantId,
        solicitationId: args.solicitationId,
        taskId: args.taskId,
      },
    },
    (data: GetEvaluationPageStateQuery | null) => {
      if (args.communication === 'SYNC') {
        const doomedEntity = result?.deleteDiscovery as DeletedEntity
        doomedEntity?.id &&
          remove(
            data?.getEvaluationTask?.discoveries || [],
            (discovery) => discovery.id === doomedEntity.id
          )

        return data
      }

      return data
    }
  )
}
