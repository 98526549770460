import { Box, Button, SxProps, Typography } from '@mui/material'
import { alpha, Theme } from '@mui/material/styles'
import { ReactComponent as TrashIcon } from 'assets/trashIcon.svg'
import { Annotation } from 'generated/graphql'
import React, { MouseEventHandler } from 'react'
import { theme } from 'theme'

export interface AnnotationCardProps extends Partial<Annotation> {
  id: string
  onRemove?: MouseEventHandler<HTMLAnchorElement>
  isNew: boolean
  handleClick: (e: React.MouseEvent, id: string) => void
  isExpanded: boolean
}

const AnnotationCard = ({
  id,
  startPage,
  startLine,
  endLine,
  text,
  onRemove,
  isNew,
  handleClick,
  isExpanded,
}: AnnotationCardProps): JSX.Element => {
  return (
    <Box
      sx={{
        '&:hover': { backgroundColor: alpha(theme.palette.primary.main, 0.15) },
        '&:hover .annotationText': { color: 'text.primary' },
        '&:last-of-type': {
          mb: 0,
        },
      }}
      mb={4}
    >
      <Box
        display="flex"
        flexDirection="column"
        pt={1}
        pb={1}
        px={3}
        sx={Object.assign(
          {},
          styles.card,
          isExpanded ? null : styles.minimized,
          styles.background,
          styles.hideRemove
        )}
        onClick={(e) => handleClick(e, id)}
      >
        <Box
          display="flex"
          justifyContent={'space-between'}
          mb={0.5}
          sx={styles.background}
        >
          <Box display="flex">
            <Typography variant="body1">{`Page ${startPage}, ${
              startLine === endLine
                ? 'line ' + startLine
                : 'lines ' + startLine + ' - ' + endLine
            }`}</Typography>
            {isNew && (
              <Typography variant="body1" ml={1} color="primary.main">
                NEW
              </Typography>
            )}
          </Box>

          {!isNew && onRemove && (
            <Button
              onClick={onRemove}
              sx={{
                zIndex: 10,
                mr: 3,
              }}
              disableRipple
              href=""
              className="removeButton"
            >
              <TrashIcon />
              <Typography variant="caption" ml={1}>
                Remove
              </Typography>
            </Button>
          )}
        </Box>
        <Typography
          variant="body2"
          sx={Object.assign({}, isExpanded ? null : styles.annotationText)}
          width="100%"
          className="annotationText"
        >
          {text}
        </Typography>
      </Box>
    </Box>
  )
}

export { AnnotationCard }

const styles: Record<string, SxProps<Theme>> = {
  card: {
    borderLeft: '3px solid',
    borderColor: 'primary.main',
  },
  minimized: {
    maxHeight: '144px',
  },
  annotationText: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  background: {
    '& :hover': { backgroundColor: 'transparent' },
  },
  hideRemove: {
    '& .removeButton': {
      visibility: 'hidden',
    },
    '&:hover .removeButton': {
      visibility: 'visible',
    },
  },
}
