import { Box, SxProps, Theme } from '@mui/material'
import SelectionBox, {
  Props as SelectionBoxProps,
} from 'components/form/SelectionBox'
import { useMemo } from 'react'
import { FieldValues } from 'react-hook-form'

interface Props<T> extends SelectionBoxProps<T> {
  itemColor?: string
  itemDefinition?: keyof T
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const RatingSelection = <T extends Record<string, any>>({
  defaultOption = 'Select One',
  defaultValue,
  disabled,
  label,
  hideDefaultOption,
  hideLabel,
  hideErrorMessage = false,
  items,
  itemColor = 'backgroundColorHex',
  itemDefinition,
  itemLabel = 'name',
  itemValue = 'id',
  readonly,
  useFormHook,
  rules,
}: Props<T>): JSX.Element => {
  const ratingContent = (item: T, _field: FieldValues) => {
    return (
      <Box sx={styles.container}>
        <Box
          sx={{
            backgroundColor: item[itemColor] ?? 'white',
            ...styles.dot,
          }}
        />
        <Box sx={styles.mx}>{item[itemLabel]}</Box>
        {itemDefinition && item[itemDefinition] && (
          <Box color="text.secondary" sx={styles.ellipsis}>
            {item[itemDefinition]}
          </Box>
        )}
      </Box>
    )
  }

  const selectedContent = ({ item }: { item: T; field: FieldValues }) => {
    return (
      <Box sx={styles.container}>
        <Box
          sx={{
            backgroundColor: item[itemColor] ?? '',
            ...styles.colorText,
          }}
        >
          {item[itemLabel]}
        </Box>
        {itemDefinition && item[itemDefinition] && (
          <Box color="text.secondary" sx={styles.ellipsis}>
            {item[itemDefinition]}
          </Box>
        )}
      </Box>
    )
  }

  const ratingItems: Array<
    T & { rating: (item: T, field: FieldValues) => JSX.Element }
  > = useMemo(() => {
    return items.map((item) => ({
      ...item,
      rating: (item, field) => ratingContent(item, field),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  return (
    <SelectionBox
      defaultOption={defaultOption}
      defaultValue={defaultValue}
      disabled={disabled}
      hideDefaultOption={hideDefaultOption}
      hideLabel={hideLabel}
      hideErrorMessage={hideErrorMessage}
      items={ratingItems}
      itemLabel="rating"
      itemValue={itemValue}
      label={label}
      readonly={readonly}
      renderValue={selectedContent}
      rules={rules}
      useFormHook={useFormHook}
    />
  )
}

const styles: Record<string, SxProps<Theme>> = {
  container: {
    alignItems: 'center',
    display: 'flex',
  },
  dot: {
    borderRadius: '50%',
    height: 8,
    width: 8,
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '150px',
  },
  mx: {
    m: (theme) => theme.spacing(0, 1),
  },
  colorText: {
    color: '#0A0045',
    borderRadius: '4px',
    m: (theme) => theme.spacing(0, 1),
    p: (theme) => theme.spacing(0, 1),
  },
}

export default RatingSelection
