import { Box } from '@mui/material'
import { BasicAccordian } from 'components/Accordian'
import { AnnotationCardList } from 'components/Annotations/AnnotationCardList'
import { BidscaleButton } from 'components/Buttons/BidscaleButton'
import { DefinitionList } from 'components/DefinitionList'
import RatingSelection from 'components/form/RatingSelection'
import SelectionBox from 'components/form/SelectionBox'
import {
  MessageAlert,
  Props as MessageAlertProps,
} from 'components/MessageAlert'
import MultiLineTextField from 'components/MultiLineTextField'
import RatingDefinitions from 'components/RatingDefinitions'
import {
  Annotation,
  Criteria,
  Discovery,
  RatingOption,
  Scalars,
} from 'generated/graphql'
import { map, orderBy } from 'lodash'
import { EvaluationError } from 'pages/Evaluation/state/useEvaluationPageState'
import { useEffect, useMemo, useState } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { theme } from 'theme'

export interface Props {
  formHook: UseFormReturn<FieldValues>
  annotationsList: Array<Annotation>
  criteria: Array<Criteria>
  disableDiscoveryTypeSelect: boolean
  discoveriesList: Array<Discovery>
  ratings: Array<RatingOption>
  criteriaDisplayIndex: number
  onSave: () => void
  onCancel: () => void
  onDelete: () => void
  onRemoveAnnotation: (id: Scalars['ID']) => void
  editingExistingDiscovery?: boolean
  deletingExistingDiscovery?: boolean
  error?: EvaluationError
}

export const DiscoveryDrawerContent = ({
  formHook,
  annotationsList,
  criteria,
  disableDiscoveryTypeSelect,
  discoveriesList,
  ratings,
  criteriaDisplayIndex,
  onSave,
  onCancel,
  onDelete,
  onRemoveAnnotation,
  editingExistingDiscovery = false,
  deletingExistingDiscovery = false,
  error,
}: Props): JSX.Element => {
  const labelledDiscoveries = useMemo(() => {
    const sortedDiscoveries = orderBy(
      discoveriesList,
      (discovery) => discovery.createdAt,
      'asc'
    )
    return map(sortedDiscoveries, (discovery, index) => {
      return {
        ...discovery,
        label: `${criteriaDisplayIndex}.${index + 1}: ${
          discovery.rating.comment
        }`,
      }
    })
  }, [discoveriesList, criteriaDisplayIndex])

  const hasErrors = useMemo(
    () =>
      !(
        Object.keys(formHook.formState.errors).length === 0 &&
        formHook.formState.errors.constructor === Object
      ),
    [formHook.formState.errors]
  )

  const defaultMessageAlertState: MessageAlertProps = useMemo(() => {
    return {
      variant: 'error',
      message: undefined,
    }
  }, [])

  const [messageAlertState, setMessageAlertState] = useState<MessageAlertProps>(
    defaultMessageAlertState
  )

  useEffect(() => {
    if (deletingExistingDiscovery) {
      setMessageAlertState({
        variant: 'warning',
        message:
          'Deleting this discovery will permanently remove it from the system.',
      })
    } else {
      setMessageAlertState(defaultMessageAlertState)
    }
    if (error) {
      setMessageAlertState({
        variant: 'error',
        message: error.message,
      })
    }
  }, [error, deletingExistingDiscovery, defaultMessageAlertState])

  const [activityIndicator, setActivityIndicator] = useState(false)

  const showMessageAlert = useMemo(
    () => hasErrors || deletingExistingDiscovery || !!error,
    [deletingExistingDiscovery, error, hasErrors]
  )

  const handleSubmit = async () => {
    setActivityIndicator(true)
    await (deletingExistingDiscovery ? onDelete() : onSave())
    setActivityIndicator(false)
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box id={'discovery-editor-top-portion'}>
        <Box id={'discovery-editor-definitions'} mt={2}>
          <BasicAccordian title={'Definitions'}>
            <Box sx={{ px: 2, pt: 1 }}>
              <DefinitionList definitions={criteria} />
              <RatingDefinitions
                data={ratings}
                title="Discovery Rating Definitions"
              />
            </Box>
          </BasicAccordian>
        </Box>
        {showMessageAlert && (
          <Box pt={3}>
            <MessageAlert {...messageAlertState} />
          </Box>
        )}
        <Box id={'discovery-editor-evaluation-criteria'}>
          <SelectionBox
            items={criteria}
            label="Evaluation Criteria"
            useFormHook={formHook}
            defaultOption={'Choose Evaluation Criteria'}
            rules={{ required: 'This field is required' }}
            hideErrorMessage={true}
            disabled={editingExistingDiscovery || deletingExistingDiscovery}
          />
        </Box>
        <Box id={'discovery-editor-discovery-type'}>
          <SelectionBox
            disabled={
              disableDiscoveryTypeSelect ||
              editingExistingDiscovery ||
              deletingExistingDiscovery
            }
            items={labelledDiscoveries}
            itemValue={'id'}
            itemLabel={'label'}
            label="Discovery Type"
            useFormHook={formHook}
            defaultOption="Add New Discovery"
            defaultValue="Add New Discovery"
            rules={{ required: 'This field is required' }}
            hideErrorMessage={true}
          />
        </Box>
        <Box id={'discovery-editor-rate-discovery'}>
          <RatingSelection
            defaultOption={'Select Rating'}
            useFormHook={formHook}
            label="Rate Discovery"
            items={ratings}
            itemColor={'backgroundColor'}
            itemDefinition="description"
            rules={{ required: 'This field is required' }}
            hideErrorMessage={true}
            disabled={deletingExistingDiscovery}
          />
        </Box>
        <Box id={'discovery-editor-annotation-cards'} my={3}>
          <AnnotationCardList
            annotations={annotationsList}
            onRemove={onRemoveAnnotation}
          />
        </Box>
      </Box>

      <Box
        id={'discovery-editor-sticky-portion'}
        sx={{
          position: 'sticky',
          bottom: 0,
          bgcolor: 'background.paper',
          py: 1,
          zIndex: 33,
          boxShadow: '0 -2px 8px -8px black',
          mx: -4,
          px: 4,
        }}
      >
        <Box id={'discovery-editor-add-comment'} mt={1}>
          <MultiLineTextField
            rows={4}
            variant={'outlined'}
            title={'Add a comment'}
            name={'Discovery Comment'}
            placeholder={'Enter comment'}
            useFormHook={formHook}
            rules={{ required: 'This field is required' }}
            disabled={deletingExistingDiscovery}
          />
        </Box>
        <Box
          id={'discovery-editor-action-buttons'}
          sx={{
            mt: 4,
            mb: 3,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <BidscaleButton
            id={'discovery-editor-cancel-button'}
            buttonVariant="cancel"
            sx={{ width: theme.spacing(26), height: '40px', mr: 3 }}
            onClick={onCancel}
          >
            CANCEL
          </BidscaleButton>
          <BidscaleButton
            id={'discovery-editor-save-or-delete-button'}
            buttonVariant={deletingExistingDiscovery ? 'delete' : 'execute'}
            activityIndicator={activityIndicator}
            onClick={formHook.handleSubmit(handleSubmit)}
            sx={{ width: theme.spacing(26), height: '40px' }}
          >
            {deletingExistingDiscovery ? 'DELETE DISCOVERY' : 'SAVE DISCOVERY'}
          </BidscaleButton>
        </Box>
      </Box>
    </Box>
  )
}
