import { Box, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { ReactComponent as BoxBookIcon } from 'assets/boxBookIcon.svg'
import { Tag } from 'components/Tag'
import { multilineEllipsis } from 'constants/styles'
import cuid from 'cuid'
import { format } from 'date-fns'
import { EvaluationTaskType } from 'generated/graphql'
import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { theme } from 'theme'

export interface TaskCardProps {
  taskId?: string
  solicitationId?: string
  taskName: string
  linkTo?: string
  newTab?: boolean
  taskDescription: string
  taskDueOrCompletedDate: string
  isComplete?: boolean
  projectName?: string
  offeror?: string
  taskTypes?: EvaluationTaskType[]
  icon?: ReactElement
}

const TaskCard = ({
  taskId,
  taskDescription,
  taskName,
  linkTo,
  newTab,
  taskDueOrCompletedDate,
  isComplete,
  projectName,
  offeror,
  taskTypes,
  icon,
}: TaskCardProps): JSX.Element => {
  const datePrefix = () => {
    if (!taskId) {
      return 'Next Due Date: '
    } else if (isComplete) {
      return 'Completed on: '
    }
    return 'Due: '
  }
  const TaskCardView = (): JSX.Element => (
    <Box
      sx={{
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        border: `1px solid ${theme.palette.info.main}`,
        '&:hover': {
          backgroundColor: !isComplete
            ? alpha(theme.palette.primary.main, 0.05)
            : 'none',
          border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
          cursor: isComplete ? 'default' : 'pointer',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        py={3}
        px={3}
        maxHeight="206px"
      >
        <Box display="flex">
          <Box
            display="flex"
            alignItems={'flex-start'}
            sx={{
              '& .book': {
                fill: !isComplete ? '#FFFFFF' : theme.palette.text.primary,
                fillOpacity: !isComplete ? '1.0' : '0.5',
              },
              '& .boxAroundBook': {
                fill: !isComplete
                  ? theme.palette.primary.main
                  : theme.palette.info.main,
              },
            }}
          >
            <Box height={'36px'} width={'36px'} pt={'4px'}>
              {icon || <BoxBookIcon />}
            </Box>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: '18px', height: 'fit-content' }}
              ml={1.5}
              my="auto"
            >
              {taskName}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="body2"
          sx={multilineEllipsis(2)}
          width="100%"
          mt={2}
          mb={3}
        >
          {taskDescription}
        </Typography>
        <Box display="flex">
          {projectName && (
            <Tag
              label={`${projectName?.substring(0, 24)}${
                projectName && projectName.length > 24 ? '...' : ''
              }`}
              variant="doc"
              sx={{
                marginRight: 2,
                color: isComplete
                  ? alpha(theme.palette.text.primary, 0.5)
                  : theme.palette.text.primary,
              }}
            />
          )}
          {offeror && (
            <Tag
              label={`${offeror.substring(0, 24)}${
                offeror.length > 24 ? '...' : ''
              }`}
              variant="doc"
              sx={{
                marginRight: 2,
                color: isComplete
                  ? alpha(theme.palette.text.primary, 0.5)
                  : theme.palette.text.primary,
              }}
            />
          )}
          {taskTypes &&
            taskTypes.map((taskType) => {
              return (
                <Tag
                  label={`${taskType.toLowerCase().substring(0, 24)}${
                    taskType.length > 24 ? '...' : ''
                  }`}
                  variant="doc"
                  sx={{
                    textTransform: 'capitalize',
                    marginRight: 2,
                    color: theme.palette.text.primary,
                  }}
                  key={`tag-${cuid()}`}
                />
              )
            })}
          <Typography variant="caption" height="fit-content" my="auto">
            {datePrefix() +
              format(new Date(taskDueOrCompletedDate), 'yyyy-MM-dd')}
          </Typography>
        </Box>
      </Box>
    </Box>
  )

  return linkTo ? (
    <Link
      to={linkTo}
      style={{
        textDecoration: 'none',
        display: 'block',
      }}
      target={newTab ? '_blank' : '_self'}
    >
      <TaskCardView />
    </Link>
  ) : (
    <TaskCardView />
  )
}

export { TaskCard }
