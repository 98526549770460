import {
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Skeleton,
  styled,
  Typography,
} from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import { TaskCard, TaskCardProps } from 'components/TaskCard'
import React, { ReactElement } from 'react'

export interface TaskAccordionProps {
  accordions: {
    accordionTitle: string
    tasks?: TaskCardProps[]
    emptyMessage: string
    icon?: ReactElement
  }[]
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.info.main}`,
  borderRight: 0,
  borderLeft: 0,
  borderBottom: 0,
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'none',
  },
}))

function TaskAccordion({ accordions }: TaskAccordionProps): JSX.Element {
  const [hovered, setHovered] = React.useState<string | false>(false)

  const getAccordionId = (index: number): string => {
    return `accordion-${index}`
  }

  const [expanded, setExpanded] = React.useState<string>(getAccordionId(0))

  const handleChange =
    (accordionNumber: number) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(
        isExpanded
          ? getAccordionId(accordionNumber)
          : openNextAccordion(accordionNumber)
      )
    }
  const openNextAccordion = (accordionNumber: number) => {
    if (accordionNumber + 1 === accordions.length) {
      return getAccordionId(0)
    } else {
      return getAccordionId(accordionNumber + 1)
    }
  }
  const getOpacity = (i: number) =>
    expanded === getAccordionId(i) || hovered === getAccordionId(i) ? 1 : 0.5

  return (
    <div>
      {accordions.map((accordion, i) => (
        <Accordion
          key={i}
          id="task-accordion"
          expanded={expanded === getAccordionId(i)}
          onChange={handleChange(i)}
          onMouseEnter={(e) => {
            e.preventDefault()
            setHovered(getAccordionId(i))
          }}
          onMouseLeave={(e) => {
            e.preventDefault()
            setHovered(false)
          }}
        >
          <AccordionSummary
            expandIcon={
              <Typography
                variant="overline"
                sx={{
                  opacity: getOpacity(i),
                }}
              >
                ({accordion?.tasks?.length ?? 0})
              </Typography>
            }
            sx={{
              height: '40px',
              minHeight: '40px',
              '& .MuiAccordionSummary-content': {
                marginTop: 0,
                marginBottom: 0,
              },
              padding: 0,
            }}
          >
            <Typography
              variant="overline"
              sx={{
                opacity: getOpacity(i),
              }}
            >
              {accordion.accordionTitle}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              px: 0,
              '& .taskCards:last-of-type': {
                marginBottom: 0,
              },
            }}
          >
            {accordion.tasks ? (
              accordion.tasks.length > 0 ? (
                accordion.tasks.map((task, index) => (
                  <Box key={index} className={'taskCards'} mb={4}>
                    <TaskCard {...task} icon={accordion.icon} />
                  </Box>
                ))
              ) : (
                <Typography mt={6} mb={11} mx="auto" width="fit-content">
                  {accordion.emptyMessage}
                </Typography>
              )
            ) : (
              <Skeleton
                variant="rectangular"
                animation="wave"
                height="184px"
                width="100%"
              />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}

export default TaskAccordion
