import { Box, SxProps, Theme, Typography } from '@mui/material'
import { BidscaleButton } from 'components/Buttons/BidscaleButton'
import { MessageAlert } from 'components/MessageAlert'
import MultiLineTextField from 'components/MultiLineTextField'
import React, { useState } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { theme } from 'theme'

export interface Props {
  active: boolean
  editing: boolean
  setEditing: (input: boolean) => void
  formHook: UseFormReturn<FieldValues>
  onSave: () => Promise<void>
  deleting: boolean
  setDeleting: (input: boolean) => void
  onDelete: () => Promise<void>
}

export const ViewAndEditComment = ({
  active,
  editing,
  deleting,
  setDeleting,
  setEditing,
  formHook,
  onSave,
  onDelete,
}: Props): JSX.Element => {
  const {
    getValues,
    setValue,
    formState: { errors },
    clearErrors,
  } = formHook
  const [comment, setComment] = useState(getValues('Comment'))
  const [rating, setRating] = useState(getValues('Rating'))
  const [activityIndicator, setActivityIndicator] = useState(false)

  const hasErrors = !(
    Object.keys(errors).length === 0 && errors.constructor === Object
  )

  const handleCancel = (e: React.MouseEvent) => {
    e.stopPropagation()

    if (editing) {
      setValue('Comment', comment)
      setValue('Rating', rating)
      clearErrors()
      setEditing(false)
    } else {
      setDeleting(false)
    }
  }

  const handleSave = async () => {
    setComment(formHook.getValues('Comment'))
    setRating(formHook.getValues('Rating'))
    await onSave()
    setEditing(false)
  }

  const handleDelete = async (e: React.MouseEvent) => {
    e.stopPropagation()
    setActivityIndicator(true)
    await onDelete()
    setDeleting(false)
    setActivityIndicator(false)
  }

  return (
    <Box
      id="discovery-view-edit-body"
      mt={2}
      mr={2}
      onClick={(e) => e.stopPropagation()}
    >
      {editing || deleting ? (
        <>
          {hasErrors && <MessageAlert variant="error" />}
          {deleting && (
            <MessageAlert
              variant="error"
              message="This will permanently delete this entry from the system."
            />
          )}
          <Box pt={2} pb={3}>
            <MultiLineTextField
              rows={5}
              variant={'outlined'}
              title={'Edit comment'}
              name={'Comment'}
              placeholder={'Enter comment'}
              useFormHook={formHook}
              rules={{ required: 'This field is required' }}
              disabled={deleting}
            />
          </Box>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <BidscaleButton
              id={'discovery-view-edit-comment-cancel-button'}
              buttonVariant="cancel"
              sx={{ mr: 3, width: theme.spacing(26), height: '40px' }}
              onClick={handleCancel}
            >
              CANCEL
            </BidscaleButton>
            {deleting ? (
              <BidscaleButton
                id={'discovery-view-edit-comment-delete-button'}
                buttonVariant="delete"
                onClick={handleDelete}
                sx={{ width: theme.spacing(26), height: '40px' }}
                activityIndicator={activityIndicator}
              >
                Delete
              </BidscaleButton>
            ) : (
              <BidscaleButton
                id={'discovery-view-edit-comment-save-button'}
                buttonVariant="execute"
                onClick={formHook.handleSubmit(handleSave)}
                sx={{ width: theme.spacing(26), height: '40px' }}
              >
                SAVE
              </BidscaleButton>
            )}
          </Box>
        </>
      ) : (
        <Typography
          variant="body2"
          sx={Object.assign(
            {},
            noOverFlowStyle,
            !active && { WebkitLineClamp: 3 }
          )}
        >
          {comment}
        </Typography>
      )}
    </Box>
  )
}

export const noOverFlowStyle: SxProps<Theme> = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}
