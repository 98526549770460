import AcquisitionTrackerPageSkeleton from './AcquisitionTrackerPageSkeleton'
import Page from './Page'
import { useAcquisitionTrackerPageState } from './state/useAcquisitionTrackerPageState'

const AcquisitionTracker = (): JSX.Element => {
  const state = useAcquisitionTrackerPageState()
  const lastUpdated = state.initiatives.reduce(
    (latest, initiative) =>
      latest > initiative.lastUpdated ? latest : initiative.lastUpdated,
    state.initiatives[0]?.lastUpdated
  )

  return (
    <>
      {state.isFetching ? (
        <AcquisitionTrackerPageSkeleton />
      ) : (
        <Page state={state} lastUpdated={lastUpdated} />
      )}
    </>
  )
}

export { AcquisitionTracker }
