import { Box, Typography } from '@mui/material'
import { ActionMenuKebab } from 'components/ActionMenuKebab'
import { ActionMenuItem } from 'components/ActionMenuPopper'
import { AnnotationCardList } from 'components/Annotations/AnnotationCardList'
import RatingSelection from 'components/form/RatingSelection'
import { format } from 'date-fns'
import {
  Discovery,
  DiscoveryInput,
  RatingOption,
  Scalars,
} from 'generated/graphql'
import { filter, indexOf } from 'lodash'
import React, { useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import { GenericDiscoveryBox } from './GenericDiscoveryBox'
import { noOverFlowStyle, ViewAndEditComment } from './ViewAndEditComment'

export interface Props {
  titleNumbering: string
  title?: string
  discovery: Discovery
  ratings: Array<RatingOption>

  disabled?: boolean
  forceActive?: boolean
  onEditDiscovery: (discoveryInput: DiscoveryInput) => Promise<boolean>
  onDeleteDiscovery: (id: Scalars['ID']) => Promise<boolean>
}

export const ViewAndEditDiscovery = ({
  titleNumbering,
  title,
  discovery,

  ratings,
  disabled = false,
  forceActive = false,
  onEditDiscovery,
  onDeleteDiscovery,
}: Props): JSX.Element => {
  const formHook = useForm({
    defaultValues: {
      Rating: discovery.rating.option,
      Comment: discovery.rating.comment,
    } as FieldValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { annotations, rating } = discovery

  const [active, setActive] = useState(forceActive || false)
  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [removedAnnotations, setRemovedAnnotations] =
    useState<Scalars['ID'][]>()

  const handleSave = async (data: Record<string, string>) => {
    const updatedAnnotations = getAnnotations()
    const updatedDiscovery: DiscoveryInput = {
      id: discovery.id,
      criteriaId: discovery.criteria.id,
      annotations: updatedAnnotations,
      rating: {
        option: data['Rating'],
        comment: data['Comment'],
      },
    }
    await onEditDiscovery(updatedDiscovery)
  }

  const handleRemoveAnnotation = (id: Scalars['ID']) => {
    if (!removedAnnotations) {
      setRemovedAnnotations([id])
    } else if (indexOf(removedAnnotations, id) < 0) {
      setRemovedAnnotations([...removedAnnotations, id])
    }
  }

  const getAnnotations = () => {
    const withoutDeletedAnnotations = removedAnnotations
      ? filter(
          annotations,
          (annotation) => indexOf(removedAnnotations, annotation.id) < 0
        )
      : annotations
    return withoutDeletedAnnotations
  }

  const actionMenuItems: ActionMenuItem[] = [
    {
      itemName: 'Edit',
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation()
        if (!editing) {
          setEditing(true)
          setActive(true)
        }
      },
    },
    {
      itemName: 'Remove',
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation()
        if (!deleting) {
          setActive(true)
          setDeleting(true)
        }
      },
    },
  ]

  const handleDelete = async () => {
    await onDeleteDiscovery(discovery.id)
  }

  return (
    <GenericDiscoveryBox
      forceActive={forceActive}
      keepActive={editing || deleting}
      setActive={setActive}
      secondaryChildren={
        <Box pt={3}>
          <AnnotationCardList
            annotations={getAnnotations()}
            onRemove={editing ? handleRemoveAnnotation : undefined}
          />
        </Box>
      }
      disabled={disabled}
      editingOrDeleting={editing || deleting}
    >
      <Box
        id="discovery-view-edit-header"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box id="discovery-view-edit-header-left-section">
          <Typography
            variant="h6"
            sx={Object.assign({}, noOverFlowStyle, {
              WebkitLineClamp: 1,
            })}
          >
            {`${titleNumbering}: ${title || rating.comment}`}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {discovery.author && (
              <Typography variant="body2">
                {`${discovery.author.firstName} ${discovery.author.lastName} -`}
                &nbsp;
              </Typography>
            )}
            <Typography variant="overline">
              {format(new Date(discovery.createdAt), 'yyyy-MM-dd')}
            </Typography>
          </Box>
        </Box>
        <Box
          id="discovery-view-edit-header-right-section"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <RatingSelection
            useFormHook={formHook}
            label="Rating"
            hideLabel={true}
            hideDefaultOption={true}
            items={ratings}
            itemColor={'backgroundColor'}
            hideErrorMessage={true}
            rules={{ required: 'This field is required' }}
            readonly={!editing}
          />
          <Box ml={2}>
            <ActionMenuKebab items={actionMenuItems} />
          </Box>
        </Box>
      </Box>
      <ViewAndEditComment
        active={active}
        editing={editing}
        setEditing={setEditing}
        formHook={formHook}
        onSave={formHook.handleSubmit(handleSave)}
        onDelete={handleDelete}
        deleting={deleting}
        setDeleting={setDeleting}
      />
    </GenericDiscoveryBox>
  )
}
