import { RatingInput, RatingOption } from 'generated/graphql'
import { FieldValues, useForm } from 'react-hook-form'

import { RecommendationDrawerContent } from './RecommendationDrawerContent'

export interface Props {
  withRating: boolean
  ratingOptions: Array<RatingOption>
  upsertRating: (inputs: { rating: RatingInput }) => void
  proposalName: string
  completeTask: () => void
  onCancel: () => void
}

export const defaultProps: Props = {
  withRating: false,
  ratingOptions: [],
  proposalName: '',
  upsertRating: (_upsertRatingInput) => {
    return
  },
  completeTask: () => {
    return
  },
  onCancel: () => {
    return
  },
}

const RecommendationDrawer = ({
  withRating,
  ratingOptions,
  upsertRating,
  proposalName,
  completeTask,
  onCancel,
}: Props): JSX.Element => {
  const formHook = useForm({
    defaultValues: {
      'Overall Recommendation': '',
      'Rate This Proposal': '',
      checkbox: false,
    } as FieldValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = (data: { [x: string]: string }) => {
    if (withRating) {
      upsertRating({
        rating: {
          comment: data['Overall Recommendation'],
          option: data['Rate This Proposal'],
        },
      })
    }
    completeTask()
  }

  return (
    <RecommendationDrawerContent
      formHook={formHook}
      onSave={formHook.handleSubmit(onSubmit)}
      onCancel={() => onCancel()}
      withRating={withRating}
      ratings={ratingOptions}
      proposalName={proposalName}
    />
  )
}

export { RecommendationDrawer }
