import { Box, SvgIconProps, Typography } from '@mui/material'
import { ReactComponent as BidscaleIcon } from 'assets/bidscaleLogoAndText2.svg'
import { ReactComponent as ContractImg } from 'assets/landing/contracts_icon.svg'
import { ReactComponent as HomeImg } from 'assets/landing/home_icon.svg'
import { ReactComponent as SolicitationImg } from 'assets/landing/solicitations_icon.svg'
import { ReactComponent as TrackerImg } from 'assets/landing/tracker_icon.svg'
import { Profile } from 'components/Profile'
import { Solicitation } from 'generated/graphql'
import { UserContext, UserRoles } from 'pages/Login/User/providers/UserProvider'
import { Fragment, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { theme } from 'theme'

import { NavigationButton } from './NavigationButton'
import { useNavigationPageState } from './state/useNavigationPaneState'

const NavigationPane = (): JSX.Element => {
  const { solicitations } = useNavigationPageState()
  return <NavigationPaneView solicitations={solicitations} />
}

export interface ViewProps {
  solicitations: Solicitation[]
}

const NavigationPaneView = ({ solicitations }: ViewProps): JSX.Element => {
  const { hasRole } = useContext(UserContext)

  return (
    <Box display="flex" height="100vh" width="100vw">
      <Box
        width="220px"
        display="flex"
        sx={{
          paddingTop: theme.spacing(0.5),
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Box
          width="100%"
          height="100vh"
          display="flex"
          flexDirection="column"
          // className={classes.navigationBar}
        >
          <Box width="172px" mr="auto" ml="auto" my={5}>
            <BidscaleIcon />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ letterSpacing: '0.12em', fontWeight: 500 }}
              ml={3}
            >
              OVERVIEW
            </Typography>
            <Box
              sx={{ borderBottom: `1px solid ${theme.palette.info.main}` }}
              width="188px"
              mx="auto"
              mb={0.75}
            ></Box>

            <NavigationButton
              title="Home"
              linkTo={'/'}
              svgIconProps={
                {
                  component: HomeImg,
                  viewBox: '0 0 20 16',
                } as Partial<SvgIconProps>
              }
            />
            {hasRole(UserRoles.ACQUISITION_TRACKER) ? (
              <NavigationButton
                title="Tracker"
                linkTo={`/initiatives`}
                svgIconProps={
                  {
                    component: TrackerImg,
                    viewBox: '0 0 16 16',
                  } as Partial<SvgIconProps>
                }
              />
            ) : null}
            {hasRole(UserRoles.CONTRACT_VIEWER) ? (
              <NavigationButton
                title="Contracts"
                linkTo={`/contracts`}
                svgIconProps={
                  {
                    component: ContractImg,
                    viewBox: '0 0 12.8 16',
                  } as Partial<SvgIconProps>
                }
              />
            ) : null}

            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ letterSpacing: '0.12em', fontWeight: 500 }}
              ml={3}
              mt={4.25}
            >
              SELECT
            </Typography>
            <Box
              sx={{ borderBottom: `1px solid ${theme.palette.info.main}` }}
              width="188px"
              mx="auto"
            ></Box>
            {solicitations.map((solicitation, index) => (
              <Fragment key={`navbar-button-${index}`}>
                <NavigationButton
                  title={solicitation.name}
                  linkTo={`/solicitations/${encodeURIComponent(
                    solicitation.id
                  )}/dashboard`}
                  pattern={`/solicitations/${encodeURIComponent(
                    solicitation.id
                  )}`}
                  svgIconProps={
                    {
                      component: SolicitationImg,
                      viewBox: '0 0 16.2 16',
                    } as Partial<SvgIconProps>
                  }
                />
              </Fragment>
            ))}
          </Box>
          <Box mt="auto" mb={5} ml={3}>
            <Profile variant="navbar" />
          </Box>
        </Box>
      </Box>
      <Box
        id="nav-bar-page-content"
        width="100%"
        display="flex"
        height="100vh"
        overflow="auto"
        py={2}
        pl={3.5}
      >
        <Outlet />
      </Box>
    </Box>
  )
}

export { NavigationPane, NavigationPaneView }
