import { Box, Typography } from '@mui/material'
import { ReactComponent as CommentIcon } from 'assets/commentIcon.svg'
import { Annotation } from 'generated/graphql'
import React, { useState } from 'react'
import { theme } from 'theme'

import { AnnotationCard } from './AnnotationCard'

export interface AnnotationCardListProps {
  annotations: Annotation[]
  onRemove?: (id: string) => void
}

const AnnotationCardList = ({
  annotations,
  onRemove,
}: AnnotationCardListProps): JSX.Element => {
  const [isExpanded, setExpanded] = useState<Record<string, boolean>>({})

  const handleClick = (e: React.MouseEvent, id: string) => {
    e.stopPropagation()
    if (isExpanded[id] === true) {
      setExpanded({ ...isExpanded, [id]: false })
    } else {
      setExpanded({ ...isExpanded, [id]: true })
    }
  }

  const handleRemove = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    annotationId: string
  ) => {
    e.stopPropagation()
    onRemove?.(annotationId)
  }

  return (
    <>
      <Box display="flex" alignItems={'center'} mb={1}>
        <Box sx={{ '& svg': { fill: theme.palette.primary.main } }}>
          <CommentIcon />
        </Box>
        <Typography
          variant="overline"
          sx={{ color: 'primary.main', ml: '12px' }}
        >
          Discovery Annotations
        </Typography>
      </Box>
      <Box sx={{ ml: '12px' }}>
        {annotations.map((annotation, i) => (
          <AnnotationCard
            {...annotation}
            id={annotation.id}
            key={annotation.id}
            onRemove={onRemove && ((e) => handleRemove(e, annotation.id))}
            isNew={i === 0}
            handleClick={handleClick}
            isExpanded={annotation.id ? isExpanded[annotation.id] : false}
          />
        ))}
      </Box>
    </>
  )
}

export { AnnotationCardList }
