import { EvaluationTask, useGetHomePageDataQuery } from 'generated/graphql'
import {
  defaultUser,
  User,
  UserContext,
  UserRoles,
} from 'pages/Login/User/providers/UserProvider'
import { useContext, useEffect, useState } from 'react'

export interface ActivityFeed {
  activityFeed: unknown
}
export interface HomePageState extends ActivityFeed {
  tasks: EvaluationTask[] | undefined
  user: User
}

export function useHomePageState(): HomePageState {
  const userContext = useContext(UserContext)
  const [tasks, setTask] = useState<EvaluationTask[] | undefined>(undefined)
  const [user, setUser] = useState<User>(defaultUser)
  // TODO: To be implemented during Activity Feed story
  const [activityFeed, _setActivityFeed] = useState<unknown>()
  const userId = userContext.user.userId
  const tenantId = userContext.user.tenant.id

  const [query] = useGetHomePageDataQuery({
    variables: { userId, tenantId },
    pause: userId === '' || tenantId === '',
  })

  useEffect(() => {
    setUser(userContext?.user)
    if (query?.data) {
      const data = query.data.listEvaluationTasksByUser as EvaluationTask[]
      setTask(data ?? [])
    } else if (
      !userContext.hasRole(UserRoles.EVALUATOR) &&
      !userContext.hasRole(UserRoles.CONTRACT_SPECIALIST)
    ) {
      setTask([])
    }
  }, [query, setUser, setTask, user, userContext])

  return {
    tasks,
    user,
    activityFeed,
  }
}
