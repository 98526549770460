import { theme as analyticsTheme } from 'analyticsTheme'
import Chart, {
  ChartData,
  ChartOptions,
  mapColors,
} from 'components/analytics/Chart'
import * as d3 from 'd3'
import { map, union } from 'lodash'
import { useCallback, useEffect, useRef } from 'react'
import { theme } from 'theme'

import Legend, { IconProps } from '../../Legend'

export interface AreaChartData {
  xLabel: string
  yLabels: string[]
  data: ChartData[]
}

export interface LineChartData {
  data: ChartData[]
}

export interface AreaChartOptions extends ChartOptions {
  xAxis?: {
    xAxis1: string
    xAxis2?: string
  }
}

export interface Props {
  data: AreaChartData
  lineData?: LineChartData
  chartOptions: Partial<AreaChartOptions>
}

const AreaChart = (props: Props): JSX.Element => {
  const d3svg = useRef<SVGSVGElement>(null)
  const chartOptions = { ...defaultChartOptions, ...props.chartOptions }

  const mappedColors = mapColors(
    props.data.yLabels,
    chartOptions?.colorPalette,
    props?.chartOptions?.colorMap
  )

  const drawGridlines = useCallback(
    (
      svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
      yScale: d3.ScaleLinear<number, number, never>,
      width: number
    ) => {
      const yAxisGrid = d3.axisLeft(yScale).tickSize(-width)

      svg
        .append('g')
        .call(yAxisGrid)
        .call((g) => g.select('.domain').remove())
        .call((g) =>
          g
            .selectAll('.tick line')
            .attr('x1', width / 13.4)
            .attr('x2', width / 1.08)
            .attr('shape-rendering', 'crispEdges')
            .attr('stroke', '#E9E8E8')
            .attr('stroke-width', '2px')
        )
    },
    []
  )

  const drawAreas = useCallback(
    (
      svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
      layers: d3.Series<{ [key: string]: number }, string>[],
      xScale: d3.ScaleBand<string>,
      yScale: d3.ScaleLinear<number, number, never>
    ) => {
      const lineGenerator = d3
        .line<Partial<ChartData>>()
        .x((_d, index) => xScale(index.toString()) as number)
        .y((sequence) => yScale(sequence[1] as number))

      const area = d3
        .area<Partial<ChartData>>()
        .x((_d, index) => xScale(index.toString()) as number)
        .y0((sequence) => yScale(sequence[0] as number))
        .y1((sequence) => yScale(sequence[1] as number))

      svg
        .append('g')
        .selectAll('path')
        .data(layers)
        .join('path')
        .attr('fill', (layer) => mappedColors[layer.key])
        .attr('fill-opacity', 0.6)
        .attr('d', area as unknown as string)

      svg
        .append('g')
        .selectAll('path')
        .data(layers)
        .join('path')
        .attr('fill', 'none')
        .attr('stroke', (layer) => mappedColors[layer.key])
        .attr('stroke-width', 2)
        .attr('d', lineGenerator as unknown as string)
    },
    [mappedColors]
  )

  const drawLineChart = useCallback(
    (
      svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
      data: ChartData[],
      xScale: d3.ScaleBand<string>,
      yScale: d3.ScaleLinear<number, number, never>
    ) => {
      const lineGenerator = d3
        .line<Partial<ChartData>>()
        .x((_d, index) => xScale(index.toString()) as number)
        .y((d) => yScale(d.value as number))

      svg
        .append('path')
        .attr('fill', 'none')
        .attr('stroke', '#003845')
        .attr('stroke-width', 3)
        .attr('d', lineGenerator(data))
    },
    []
  )

  const drawYAxisLabel = useCallback(
    (
      svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
      label: string,
      yScale: d3.ScaleLinear<number, number, never>,
      height: number,
      width: number
    ) => {
      const textTheme = theme.typography.body2
      const customTicks = ['LOW', 'HIGH']
      const yAxis = d3
        .axisLeft(yScale)
        .tickValues(yScale.domain())
        .tickFormat((d) =>
          yScale.domain()[d as number] === 0 ? customTicks[0] : customTicks[1]
        )

      svg
        .append('g')
        .classed('y', true)
        .classed('axis', true)

        .attr('transform', `translate(${width / 19}, 0)`)
        .call(yAxis)
        //removes y axis line
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick line').remove())
        .call((g) =>
          g
            .selectAll('.tick text')
            .style('text-anchor', 'middle')
            .attr('dx', '2px')
            .attr('dy', 5)
            .attr('fill', theme.palette.text.primary)
            .attr('font-size', theme.typography.caption.fontSize || '14px')
            .attr(
              'font-family',
              theme.typography.caption.fontFamily || 'Roboto'
            )
        )

      svg
        .append('text')
        .attr('class', 'y label')
        .attr('fill', theme.palette.text.primary)
        .attr('font-size', textTheme.fontSize || '14px')
        .attr('font-weight', textTheme.fontWeight || '400')
        .attr('font-family', textTheme.fontFamily || 'Roboto')
        .attr('text-anchor', 'middle')
        .attr('x', 0 - height / 2 - 15)
        .attr('dy', width / 25)
        .attr('transform', 'rotate(-90)')
        .text(label)
    },
    []
  )

  const drawXAxis = useCallback(
    (
      svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
      label: string,
      data: ChartData[],
      xScale: d3.ScaleBand<string>,
      xAxis1: string,
      height: number,
      width: number
    ) => {
      const textTheme = theme.typography.caption
      const textLabelTheme = theme.typography.body2

      const xAxisMonth = d3
        .axisBottom(xScale)
        .tickFormat((_d, index) => {
          return data[index][xAxis1]?.toString() || ''
        })
        .tickSizeInner(0)
        .tickSizeOuter(0)

      svg
        .append('g')
        .classed('x', true)
        .classed('axis', true)
        .attr('transform', `translate(0, ${height})`)
        .call(xAxisMonth)
        .attr('stroke-opacity', 0)
        .call((g) =>
          g
            .selectAll('.tick text')
            .style('text-anchor', 'middle')
            .attr('x', -11)
            .attr('dy', 16)
            .attr('fill', theme.palette.text.primary)
            .attr('font-size', textTheme.fontSize || '14px')
            .attr('font-family', textTheme.fontFamily || 'Roboto')
        )
      svg
        .append('text')
        .attr('class', 'x label')
        .attr('fill', theme.palette.text.primary)
        .attr('font-size', textLabelTheme.fontSize || '16px')
        .attr('font-weight', '500')
        .attr(
          'font-family',
          theme.typography.body2.fontFamily || 'proxima-nova'
        )
        .attr('text-anchor', 'middle')
        .attr('x', width / 2)
        .attr('dy', height + 50)
        .text(label)
    },
    []
  )

  const drawChart = useCallback(() => {
    if (d3svg.current && props.data.data.length > 0) {
      const svg = d3.select(d3svg.current)
      svg.selectAll('*').remove()

      const customHeight = d3svg.current?.height.baseVal.value - 60
      const customWidth = d3svg.current?.width.baseVal.value * 1.06

      // stacks / layers
      const allKeys = props.data.yLabels
      const data = props.data.data
      const xAxis1 = props.chartOptions.xAxis?.xAxis1 as string
      const stackGenerator = d3.stack().keys(allKeys)
      const layers = stackGenerator(data as Iterable<{ [key: string]: number }>)

      const extent = [
        0,
        d3.max(layers, (layer) => d3.max(layer, (sequence) => sequence[1])),
      ] as number[]
      // scales
      const xScale = d3
        .scaleBand()
        .domain(data.map((_d, index) => index.toString()) as Iterable<string>)
        .range([30, customWidth - 10])
        .padding(0.76)
      const yScale = d3.scaleLinear().domain(extent).range([customHeight, 20])

      // rendering
      drawGridlines(svg, yScale, customWidth)
      drawAreas(svg, layers, xScale, yScale)
      // axes
      drawXAxis(
        svg,
        'Month Forecast',
        data,
        xScale,
        xAxis1,
        customHeight,
        customWidth
      )
      drawYAxisLabel(
        svg,
        'Workload Threshold',
        yScale,
        customHeight,
        customWidth
      )

      //append projection line
      if (props?.lineData?.data) {
        drawLineChart(svg, props?.lineData?.data, xScale, yScale)
      }
    }
  }, [
    props.data.data,
    props.data.yLabels,
    props.chartOptions.xAxis?.xAxis1,
    props?.lineData?.data,
    drawGridlines,
    drawAreas,
    drawXAxis,
    drawYAxisLabel,
    drawLineChart,
  ])

  useEffect(() => {
    drawChart()
  }, [drawChart])

  const legend = () => {
    const icons = union(
      map(
        props.data.yLabels,
        (label) =>
          label && {
            label,
          }
      )
    ) as IconProps[]
    const lineProps = {
      label: props.lineData?.data.length ? 'Average Threshold' : '',
      shape: 'Solid Line',
      color: 'text.primary',
    } as IconProps

    const iconsCustom: IconProps[] = props.lineData?.data.length
      ? (union(icons, [lineProps]) as IconProps[])
      : icons

    return (
      <Legend
        chartOptions={{
          colorPalette: chartOptions?.colorPalette,
          colorMap: mappedColors,
        }}
        icons={iconsCustom}
      />
    )
  }

  return (
    <Chart
      svgRef={d3svg}
      draw={drawChart}
      legend={chartOptions.showLegend && legend()}
    />
  )
}

const defaultChartOptions: AreaChartOptions = {
  colorPalette: [
    analyticsTheme.palette.primary.main,
    analyticsTheme.palette.success.main,
    analyticsTheme.palette.info.main,
    analyticsTheme.palette.warning.main,
  ],
}

export default AreaChart
