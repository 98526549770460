import { alpha, Box, SxProps, Theme, Typography } from '@mui/material'
import { ReactComponent as HeartBeatIcon } from 'assets/heartBeat.svg'
import DataTable, { Props as DataTableProps } from 'components/DataTable'
import {
  DateRenderer,
  TextRenderer,
} from 'components/DataTable/GenericRenderers'
import { InfoButton } from 'components/InfoButton'
import { Chip } from 'components/Tag/Chip'
import cuid from 'cuid'
import { isAfter, sub } from 'date-fns'
import { Initiative } from 'generated/graphql'
import React, { useState } from 'react'
import { theme } from 'theme'
import { dateToString } from 'utils/date'

import { AquisitionTable, EscalationStatus, UpdatedDate } from '../constants'
import { calculateWIPRatio } from '../state/useAcquisitionTrackerPageState'
import { ContractingActionsTable } from './ContractingActionsTable'

export interface Props {
  initiatives: Initiative[]
}

const isCollapsible = (data: Initiative) => {
  return !!data.contractingActions.length
}

export const ActiveInitiativesTable = (props: Props): JSX.Element => {
  const lastUpdated = (date: Date) => {
    const now = new Date()
    now.setHours(0, 0, 0, 1)
    const instancedDate = new Date(date)
    if (isAfter(sub(now, { days: 7 }), instancedDate)) {
      return UpdatedDate.RED
    } else if (isAfter(sub(now, { days: 3 }), instancedDate)) {
      return UpdatedDate.GRAY
    }
    return UpdatedDate.GREEN
  }
  const [infoOpen, setInfoOpen] = useState('')

  const InitiativeRenderer = (data: Initiative) => {
    const infoPopperData = [
      { label: 'Last Updated:', info: data.lastUpdated },
      { label: 'Action Value:', info: data.actionValue },
      { label: 'Need By Date:', info: data.needByDate },
      { label: 'Contract POC:', info: data.contractPoc },
      { label: 'PMO POC:', info: data.pmoPoc },
      { label: 'Action Type:', info: data.actionType },
      { label: 'Funding Type:', info: data.fundingType },
    ]
    return (
      <Box
        display="flex"
        flex={1}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box ml={1}>
          <Typography>{data.name}</Typography>
        </Box>
        <InfoButton
          label={data.id + data.name}
          openInfoLabel={infoOpen}
          setOpenInfoLabel={setInfoOpen}
        >
          <Box display="flex" flexDirection="column">
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                {data.name}
              </Typography>
            </Box>
            {infoPopperData.map((popperData) => (
              <Box display="flex" mt={1} key={`info-popper-${cuid()}`}>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 600, color: '#0E0E0E' }}
                >
                  {popperData.label}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 500, color: '#0E0E0E', marginLeft: 0.75 }}
                >
                  {popperData.info || '--'}
                </Typography>
              </Box>
            ))}
          </Box>
        </InfoButton>
      </Box>
    )
  }

  const LastUpdateRenderer = (data: Initiative) => {
    const label = lastUpdated(data.lastUpdated)
    const style = styles[label] as SxProps<Theme>
    return <Chip sx={style} label={label || '--'} />
  }

  const BrokenImageRenderer = (data: Initiative) => {
    const colorRatio = calculateWIPRatio(data.contractingActions)
    const getColor = () => {
      if (colorRatio < 0.5) {
        return styles.red
      } else if (colorRatio > 1) {
        return styles.green
      }
      return styles.gray
    }

    return (
      <Box display="flex">
        <HeartBeatIcon style={getColor() as React.CSSProperties} />
      </Box>
    )
  }

  const EscalationRenderer = (data: Initiative) => {
    const mapStatusToStyle = () => {
      if (
        data.escalationStatus &&
        [EscalationStatus.CSUITE, EscalationStatus.KO_PO].includes(
          data.escalationStatus as EscalationStatus
        )
      ) {
        return styles[UpdatedDate.GREEN]
      } else if (data.escalationStatus === EscalationStatus.PEO) {
        return styles[UpdatedDate.RED]
      } else {
        return styles.GRAY
      }
    }

    return (
      <Box>
        <Chip
          label={data.escalationStatus || 'Unknown'}
          sx={mapStatusToStyle() as SxProps<Theme>}
        />
      </Box>
    )
  }

  const collapseRenderer = (data: Initiative): JSX.Element => {
    return <ContractingActionsTable {...data} />
  }

  const tableConfig: DataTableProps<Initiative> = {
    columns: [
      {
        name: AquisitionTable.INITIATIVE,
        renderer: InitiativeRenderer,
        sort: (item) => item.name,
        headerSx: styles.columnHeader as SxProps<Theme>,
      },
      {
        name: AquisitionTable.AWARD_DATE,
        renderer: DateRenderer,
        key: 'awardDate',
        sort: (item) => item.awardDate,
        headerSx: styles.columnHeader as SxProps<Theme>,
      },
      {
        name: AquisitionTable.PHASE,
        renderer: TextRenderer,
        key: 'phaseStatus',
        sort: (item) => item.phaseStatus?.toLowerCase() || '',
        headerSx: styles.columnHeader as SxProps<Theme>,
      },
      {
        name: AquisitionTable.LAST_UPDATED,
        renderer: LastUpdateRenderer,
        sort: (item) => dateToString(item.lastUpdated),
        headerSx: styles.columnHeader as SxProps<Theme>,
      },
      {
        name: AquisitionTable.BUSINESS,
        renderer: TextRenderer,
        key: 'businessUnit',
        sort: (item) => item.businessUnit?.toLowerCase() || '',
        headerSx: styles.columnHeader as SxProps<Theme>,
      },
      {
        name: AquisitionTable.WIP,
        renderer: BrokenImageRenderer,
        sort: (item) => calculateWIPRatio(item.contractingActions),
        headerSx: styles.columnHeader as SxProps<Theme>,
      },
      {
        name: AquisitionTable.ESCALATION,
        renderer: EscalationRenderer,
        sort: (item) => item.escalationStatus?.toLowerCase() || '',
        headerSx: styles.columnHeader as SxProps<Theme>,
      },
    ],
    data: props.initiatives,
    rowsPerPage: 10,
    collapsible: collapseRenderer,
    isCollapsible: isCollapsible,
  }

  return <DataTable {...tableConfig} />
}

const styles: Record<string, SxProps<Theme> | React.CSSProperties> = {
  green: {
    stroke: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
    borderRadius: '2px',
  },
  gray: {
    stroke: theme.palette.text.secondary,
    backgroundColor: theme.palette.info.main,
    borderRadius: '2px',
  },
  red: {
    stroke: theme.palette.error.dark,
    backgroundColor: theme.palette.error.light,
    borderRadius: '2px',
  },
  [UpdatedDate.GREEN]: {
    color: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  [UpdatedDate.GRAY]: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.info.main,
  },
  [UpdatedDate.RED]: {
    color: `${theme.palette.error.dark} !important`,
    backgroundColor: theme.palette.error.light,
  },
  columnHeader: {
    fontWeight: 500,
  },
}
