import { Auth } from 'aws-amplify'
import { some } from 'lodash'
import React, { ReactNode, useEffect, useState } from 'react'

export enum UserRoles {
  CONTRACT_SPECIALIST = 'ContractSpecialist',
  ACQUISITION_TRACKER = 'AcquisitionTracker',
  CONTRACT_VIEWER = 'ContractViewer',
  EVALUATOR = 'Evaluator',
}

export interface User {
  username: string
  email: string
  firstName: string
  familyName: string
  createdDate: Date
  tenant: {
    id: string
    name: string
    tier: string
  }
  role: string[]
  userId: string
  title: string
  jwt: string
  refreshToken: string
  groups: string[]
  tenants: Tenant[]
}

export interface Tenant {
  name: string
  id: string
  tier: string
}

interface CognitoTenant {
  pk: string
  sk: string
  gsi_1_pk: string
  gsi_1_sk: string
  gsi_2_pk: string
  gsi_2_sk: string
  name: string
  status: string
  tier: string
  type: string
}

export interface CognitoTenantUser {
  pk: string
  sk: string
  gsi_1_pk: string
  gsi_1_sk: string
  gsi_2_pk: string
  gsi_2_sk: string
  priority: string
  role: string[]
  status: string
  tenant: CognitoTenant
  title: string
  type: string
}

export interface CognitoUser {
  pk: string
  sk: string
  gsi_1_pk: string
  gsi_1_sk: string
  gsi_2_pk: string
  gsi_2_sk: string
  email: string
  first_name: string
  last_name: string
  type: string
}

export interface UserContextType {
  user: User
  hasRole: (role: UserRoles) => boolean
  setUser: () => void
}

export interface Props {
  children: ReactNode
}

export const defaultUser: User = {
  username: '',
  email: '',
  firstName: '',
  familyName: '',
  createdDate: new Date(),
  tenant: {
    id: '',
    name: '',
    tier: '',
  },
  role: [],
  userId: '',
  title: '',
  jwt: '',
  refreshToken: '',
  groups: [],
  tenants: [],
}

const UserContext = React.createContext<UserContextType>({
  user: defaultUser,
  hasRole: (_role) => false,
  setUser: () => {
    return
  },
})

const UserProvider = ({ children }: Props): JSX.Element => {
  const [userInfo, setUserInfo] = useState<User>(defaultUser)
  const { Provider } = UserContext

  useEffect(() => {
    setUser()
  }, [])

  async function setUser() {
    try {
      const tokenInfo = await Auth.currentAuthenticatedUser()
      const idTokenPayload = tokenInfo?.signInUserSession?.idToken?.payload
      const tenantUsers = JSON.parse(
        idTokenPayload?.tenantUsers
      ) as CognitoTenantUser[]

      const user = JSON.parse(idTokenPayload?.user)?.[0] as CognitoUser
      const primaryTenantUser = tenantUsers.filter(
        (user) => user.priority === 'PRIMARY'
      )[0]
      const tenants = tenantUsers.map((user) => ({
        name: user.tenant.name,
        id: user.tenant.gsi_1_pk,
        tier: primaryTenantUser.tenant?.tier,
      }))

      const userInfo = {
        username: tokenInfo.username,
        email: user.email,
        firstName: user.first_name,
        familyName: user.last_name,
        createdDate: new Date(primaryTenantUser.gsi_1_sk),
        tenant: {
          id: primaryTenantUser.tenant?.pk,
          name: primaryTenantUser.tenant?.name,
          tier: primaryTenantUser.tenant?.tier,
        },
        role: primaryTenantUser.role,
        userId: primaryTenantUser.sk,
        title: primaryTenantUser.title,
        jwt: 'Bearer ' + tokenInfo.signInUserSession?.idToken?.jwtToken,
        refreshToken: tokenInfo.signInUserSession?.refreshToken?.token,
        groups:
          tokenInfo?.signInUserSession?.accessToken?.payload?.[
            'cognito:groups'
          ],
        tenants: tenants,
      }
      setUserInfo(userInfo)
    } catch {
      setUserInfo(defaultUser)
    }
  }

  const hasRole = (role: UserRoles) =>
    some(userInfo.role, (userRole) => userRole === role)

  return (
    <Provider value={{ user: userInfo, hasRole: hasRole, setUser: setUser }}>
      {children}
    </Provider>
  )
}

export { UserContext, UserProvider }
