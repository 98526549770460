import { Box, Divider, Typography } from '@mui/material'
import { BasicAccordian } from 'components/Accordian'
import { ControlledFilters } from 'components/RadioGroup/ControlledFilters'
import RatingDefinitions from 'components/RatingDefinitions'
import { ViewAndEditDiscovery } from 'components/ViewAndEditDiscovery'
import { GenericDiscoveryBox } from 'components/ViewAndEditDiscovery/GenericDiscoveryBox'
import {
  Criteria,
  DiscoveryInput,
  EvaluationTask,
  Scalars,
} from 'generated/graphql'
import { useFilteredDiscoveries } from 'hooks/useFilteredData/useFilteredDiscoveries'
import { filter, map, orderBy } from 'lodash'
import { SyntheticEvent, useState } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from './CriteriaDrawerAccordion'

export interface Props {
  task: EvaluationTask
  onEditDiscovery: (discoveryInput: DiscoveryInput) => Promise<boolean>
  onDeleteDiscovery: (discoveryId: Scalars['ID']) => Promise<boolean>
}

export const CriteriaDrawerContent = ({
  task,
  onEditDiscovery,
  onDeleteDiscovery,
}: Props): JSX.Element => {
  const [expanded, setExpanded] = useState<string | false>(false)
  const { filtersData, setFiltersData, getFilteredDiscoveries } =
    useFilteredDiscoveries(task)

  const handleChange =
    (panel: string) => (_event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const getDiscoveries = (criteria: Criteria) => {
    const existingDiscoveries = filter(
      getFilteredDiscoveries(),
      (discovery) => discovery.criteria.id === criteria.id
    )

    return existingDiscoveries
  }

  const getCriteria = () => {
    return orderBy(task?.factor.criteria ?? [], (item) => item.name, 'asc')
  }

  return (
    <Box>
      {filtersData.length > 0 && (
        <Box position={'relative'} pb={4} mx={-3}>
          <ControlledFilters
            filtersData={filtersData}
            setFiltersData={setFiltersData}
            actionButtonsPlacement={'bottom'}
          />
        </Box>
      )}
      <Box pt={5}>
        <BasicAccordian title={'Definitions'}>
          <Box sx={{ px: 2, pt: 1 }}>
            <Typography variant="subtitle2" mb={1}>
              Factor Definition
            </Typography>
            <Typography variant="body2" my={2}>
              {task.factor.description}
            </Typography>
            <RatingDefinitions data={task.factor.overallRatingOptions} />
          </Box>
        </BasicAccordian>
      </Box>
      <Box pt={5}>
        <Divider />
      </Box>
      <Box pb={4}>
        {map(getCriteria(), (criteria, criteriaIndex) => (
          <Accordion
            key={criteriaIndex}
            expanded={expanded === `panel${criteriaIndex + 1}`}
            onChange={handleChange(`panel${criteriaIndex + 1}`)}
          >
            <AccordionSummary
              aria-controls={`panel${criteriaIndex + 1}d-content`}
              id={`panel${criteriaIndex + 1}d-header`}
            >
              <Typography variant="h6">{`${criteriaIndex + 1}.0 ${
                criteria.name
              }`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" mb={4}>
                {criteria.description}
              </Typography>
              <RatingDefinitions
                data={task.factor.discoveryRatingOptions}
                title="Discovery Rating Definitions"
              />
              <Typography variant="h6" pb={2}>
                Discoveries
              </Typography>
              {getDiscoveries(criteria).length > 0 ? (
                map(getDiscoveries(criteria), (discovery, discoveryIndex) => (
                  <Box pb={3} key={discovery.id}>
                    <ViewAndEditDiscovery
                      titleNumbering={`${criteriaIndex + 1}.${
                        discoveryIndex + 1
                      }`}
                      discovery={discovery}
                      ratings={task.factor.discoveryRatingOptions}
                      onEditDiscovery={onEditDiscovery}
                      onDeleteDiscovery={onDeleteDiscovery}
                    />
                  </Box>
                ))
              ) : (
                <Box pb={3}>
                  <GenericDiscoveryBox disabled editingOrDeleting={false} />
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  )
}
