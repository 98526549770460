import { Box, Typography } from '@mui/material'
import { multilineEllipsis } from 'constants/styles'
import React from 'react'
import { theme } from 'theme'

export interface ActivityFeedProps {
  title?: string
  subtitle?: string
  items?: ActivityFeedItemProps[]
}

export interface ActivityFeedItemProps {
  icon?: JSX.Element
  title?: string
  date?: string
  user?: string
  read?: boolean
}

export const ActivityFeedView = ({
  title = 'activity feed',
  subtitle,
  items,
}: ActivityFeedProps): JSX.Element => {
  return (
    <Box id={'activity-feed'} display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="overline" textTransform="uppercase">
          {title}
        </Typography>
        <Typography variant="overline">{`(${items?.length ?? 0})`}</Typography>
      </Box>
      <Box mt={2} mb={1}>
        <Typography variant="caption" color="text.secondary">
          {subtitle ?? 'There is currently no activity to show'}
        </Typography>
      </Box>
      <Box>
        {items?.length ? (
          <>
            {items.map((item, index) => (
              <ActivityFeedItemMemo key={`activity-feed-${index}`} {...item} />
            ))}
          </>
        ) : (
          <ActivityFeedItemMemo />
        )}
      </Box>
    </Box>
  )
}

const ActivityFeedItemMemo = React.memo(
  ({ icon, title, date, user, read }: ActivityFeedItemProps): JSX.Element => {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        mb={1}
        mt={3}
        pb={2}
        borderBottom={`1px solid ${theme.palette.info.main}`}
      >
        <Box display="flex" alignItems="flex-top" width={'70%'}>
          <Box mr={1.5} pt={0.5}>
            {icon ?? (
              <Box
                p={1.5}
                sx={{
                  border: `4px dashed ${theme.palette.info.main}`,
                  bgcolor: 'info.light',
                }}
              />
            )}
          </Box>
          <Box width={1}>
            {title ? (
              <Typography sx={multilineEllipsis(2)} variant="subtitle2">
                {title}
              </Typography>
            ) : (
              <Typography variant="subtitle2" width="284px" textAlign="left">
                Your activity will be updated here. Come back soon to see your
                progress!
              </Typography>
            )}

            {user && <Typography variant="caption">{user}</Typography>}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="caption">{date}</Typography>
          {!!read && (
            <Box ml={1}>
              <Typography variant="caption" color="#0064FF" fontSize={24}>
                ●
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    )
  }
)
