import { Box } from '@mui/material'
import { AnnotationInput, Discovery } from 'generated/graphql'
import { Dispatch, SetStateAction } from 'react'
import { PDFPageProxy } from 'react-pdf'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'

import { DocumentState } from '../Page'
import { WrappedPage } from './Page/WrappedPage'

const NUMBER_OF_PAGES_TO_BE_LOADED = 5

export interface PDFProps {
  pageInView: number
  setPageInView: (pageInView: number) => void
  documentState: DocumentState
  setDocumentState: Dispatch<SetStateAction<DocumentState>>
  scale: number
  file?: string
  activeAnnotation?: string
  passiveDiscoveries?: Discovery[]
  openAddDiscoveryDrawer: (annotationInput: AnnotationInput) => void
  openViewDiscoveriesDrawer: (discoveryIds: string[]) => void
}

function PDFViewer({
  scale,
  file,
  pageInView,
  setPageInView,
  documentState,
  setDocumentState,
  activeAnnotation,
  passiveDiscoveries,
  openAddDiscoveryDrawer,
  openViewDiscoveriesDrawer,
}: PDFProps): JSX.Element {
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setDocumentState((previous) => ({ ...previous, numPages: numPages }))
  }

  const onInitialPageLoad = (page: PDFPageProxy) => {
    setDocumentState((previous) => ({
      ...previous,
      pageWidth: page.width,
      pageHeight: page.height,
    }))
  }

  const isPageInView = (page: number) => {
    return Math.abs(pageInView - page) < NUMBER_OF_PAGES_TO_BE_LOADED
  }

  const renderPages =
    documentState.numPages > 0 &&
    documentState.pageWidth > 0 &&
    documentState.pageHeight > 0

  return (
    <Box
      display="flex"
      sx={{
        '& ::selection': {
          background: 'rgba(0, 100, 255, 0.45)',
        },
        '& .react-pdf__Document': {
          height: 'fit-content',
        },
      }}
      id={'pdf-container'}
      height="fit-content"
      width="fit-content"
      mx="auto"
      justifyContent="center"
    >
      <Box mx="auto" width="fit-content">
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            pageNumber={1}
            renderMode="none"
            onLoadSuccess={(page: unknown) =>
              onInitialPageLoad(page as PDFPageProxy)
            }
          />

          {renderPages &&
            [...Array(documentState.numPages)].map((_, index) => (
              <WrappedPage
                key={index + 1}
                scale={scale}
                width={documentState.pageWidth || 0}
                height={documentState.pageHeight || 0}
                pageNumber={index + 1}
                setPageInView={(page: number) => setPageInView(page)}
                render={isPageInView(index + 1)}
                passiveDiscoveries={passiveDiscoveries}
                activeAnnotation={activeAnnotation}
                openAddDiscoveryDrawer={openAddDiscoveryDrawer}
                openViewDiscoveriesDrawer={openViewDiscoveriesDrawer}
              />
            ))}
        </Document>
      </Box>
    </Box>
  )
}

export default PDFViewer
