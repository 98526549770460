import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import EvaluationPageSkeleton from './EvaluationPageSkeleton'
import { Page } from './Page'
import { useEvaluationPageState } from './state/useEvaluationPageState'

const Evaluation = (): JSX.Element => {
  const { evaluationTaskId, solicitationId } =
    useParams<{ evaluationTaskId: string; solicitationId: string }>()
  const {
    evaluationTask,

    addDiscovery,
    editDiscovery,
    deleteDiscovery,
    upsertRating,
    completeTask,
    startTask,
    isFetching,
    error,
    clearError,
  } = useEvaluationPageState({
    solicitationId: decodeURIComponent(solicitationId || ''),
    evaluationTaskId: decodeURIComponent(evaluationTaskId || ''),
  })

  const file = useMemo(
    () => evaluationTask?.proposal.files?.[0].transfer.presignedUrl,
    [evaluationTask]
  )

  return (
    <>
      {isFetching ? (
        <EvaluationPageSkeleton />
      ) : (
        <Page
          evaluationTask={evaluationTask}
          file={file}
          addDiscovery={addDiscovery}
          editDiscovery={editDiscovery}
          deleteDiscovery={deleteDiscovery}
          upsertRating={upsertRating}
          completeTask={completeTask}
          startTask={startTask}
          error={error}
          clearError={clearError}
        />
      )}
    </>
  )
}

export { Evaluation }
