import { Box } from '@mui/material'
import { ViewAndEditDiscovery } from 'components/ViewAndEditDiscovery'
import {
  Discovery,
  DiscoveryInput,
  RatingOption,
  Scalars,
} from 'generated/graphql'
import { DiscoveryPositions } from 'hooks/useSortedCriteria'
import { filter, indexOf, map } from 'lodash'
import { useState } from 'react'

interface Props {
  discoveries: Discovery[]
  ratings: RatingOption[]
  discoveryPositions: DiscoveryPositions[]

  onEditDiscovery: (discoveryInput: DiscoveryInput) => Promise<boolean>
  onDeleteDiscovery: (discoveryId: Scalars['ID']) => Promise<boolean>
}

const ViewDiscoveriesDrawerContent = ({
  discoveries,
  ratings,
  discoveryPositions,

  onEditDiscovery,
  onDeleteDiscovery,
}: Props): JSX.Element => {
  const [removedDiscoveries, setRemovedDiscoveries] =
    useState<Scalars['ID'][]>()

  const handleRemoveDiscovery = async (id: Scalars['ID']) => {
    const removedSuccessfully = await onDeleteDiscovery(id)
    if (removedSuccessfully) {
      if (!removedDiscoveries) {
        setRemovedDiscoveries([id])
      } else if (indexOf(removedDiscoveries, id) < 0) {
        setRemovedDiscoveries([...removedDiscoveries, id])
      }
    }
    return removedSuccessfully
  }

  const getDiscoveries = () => {
    const withoutDeletedDiscoveries = removedDiscoveries
      ? filter(
          discoveries,
          (discovery) => indexOf(removedDiscoveries, discovery.id) < 0
        )
      : discoveries
    return withoutDeletedDiscoveries
  }

  const ViewDiscoveryCard = (discovery: Discovery): JSX.Element => {
    const discoveryPosition = discoveryPositions.find(
      (discoveryNum) => discovery.id === discoveryNum.id
    )

    return (
      <ViewAndEditDiscovery
        discovery={discovery}
        titleNumbering={`${discoveryPosition?.order}`}
        title={`Discovery`}
        ratings={ratings}
        onEditDiscovery={onEditDiscovery}
        onDeleteDiscovery={handleRemoveDiscovery}
      />
    )
  }

  return (
    <>
      {map(getDiscoveries(), (discovery) => (
        <Box my={4} key={discovery.id}>
          <ViewDiscoveryCard {...discovery} />
        </Box>
      ))}
    </>
  )
}

export default ViewDiscoveriesDrawerContent
