import { Box, PopperProps } from '@mui/material'
import { ReactComponent as CommentIcon } from 'assets/commentIcon.svg'
import { ActionMenuPopper } from 'components/ActionMenuPopper'
import {
  Annotation as DiscoveryAnnotation,
  AnnotationInput,
  Discovery,
} from 'generated/graphql'
import { clone, reduce, sortBy, zip } from 'lodash'
import { pipe } from 'lodash/fp'
import { pageNumberId } from 'pages/Evaluation/state/usePdfNavigation'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import {
  PDFPageItem,
  PDFPageProxy,
  TextItem,
  TextLayerItemInternal,
} from 'react-pdf'
import { Page } from 'react-pdf/dist/esm/entry.webpack'
import { theme } from 'theme'

import Highlight from './Highlight'
import HighlightBase from './HighlightBase'
import { generateTextItems, reduceOverlappedItems } from './HighlightFunctions'
import { LoadingPage } from './LoadingPage'
import {
  indexTextItems,
  LineMetadata,
  makeAnnotation,
  selectedItems as selectedItemsFromAnnotation,
  SelectedTextItem,
} from './PDFMetadata'

interface Props {
  width: number
  height: number
  scale: number
  pageNumber: number
  render: boolean
  setPageInView: (page: number) => void
  passiveDiscoveries?: Discovery[]
  activeAnnotation?: string
  openAddDiscoveryDrawer: (annotationInput: AnnotationInput) => void
  openViewDiscoveriesDrawer: (discoveryIds: string[]) => void
}
export interface WrappedSelectedTextItem {
  item: SelectedTextItem
  annotation: DiscoveryAnnotation
  discovery: Discovery
}

interface WrappedDiscoveryAnnotation {
  annotation: DiscoveryAnnotation
  discovery: Discovery
}
interface LineNumberState {
  annotation(selection: Selection | null): DiscoveryAnnotation | undefined
  selectedItems(
    annotation: WrappedDiscoveryAnnotation
  ): WrappedSelectedTextItem[]
  lineNumberLookup: Map<number, LineMetadata[]>
  isValid(itemIndex: number): boolean
}

export interface HighlightData {
  top: number
  left: number
  width: number
  height: number
  annotationIds: string[]
  discoveryIds: string[]
}

const annotationsOnPage = (
  discoveries: Discovery[],
  currentPage: number
): WrappedDiscoveryAnnotation[] => {
  return discoveries
    .flatMap((discovery) =>
      (discovery?.annotations || []).map((annotation) => ({
        annotation: annotation,
        discovery: discovery,
      }))
    )
    .filter(
      ({ annotation }) =>
        annotation.startPage <= currentPage && annotation.endPage >= currentPage
    )
}

const WrappedPage = ({
  width,
  height,
  scale,
  pageNumber,
  render,
  setPageInView,
  passiveDiscoveries,
  activeAnnotation,
  openAddDiscoveryDrawer,
  openViewDiscoveriesDrawer,
}: Props): JSX.Element => {
  const { ref } = useInView({
    threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9],
    onChange: (inView, _entry) => {
      if (inView) {
        setPageInView(pageNumber)
      }
    },
  })
  const [viewport, setViewPort] = useState<{
    viewbox: [number, number, number, number]
    defaultSideways: boolean
  }>({
    viewbox: [0, 0, 0, 0],
    defaultSideways: false,
  })
  const [lineMetadata, setLineMetadata] = useState<LineNumberState>()

  const [selectedItems, setSelectedItems] = useState<WrappedSelectedTextItem[]>(
    []
  )

  const [highlightsData, setHighlightsData] = useState<HighlightData[]>([])

  const [anchorEl, setAnchorEl] = useState<PopperProps['anchorEl']>(null)
  const [isOpen, setIsOpen] = useState(false)

  const pageRef = useRef(null)

  const reduceToTextItemSelection = useCallback(
    (annotations: WrappedDiscoveryAnnotation[]) =>
      reduce(
        annotations,
        function (
          items: WrappedSelectedTextItem[],
          annotation: WrappedDiscoveryAnnotation
        ) {
          return [...items, ...(lineMetadata?.selectedItems(annotation) || [])]
        },
        []
      ),
    [lineMetadata]
  )

  useEffect(() => {
    const pipeline = pipe(reduceToTextItemSelection, setSelectedItems)
    if (passiveDiscoveries) {
      pipeline(annotationsOnPage(passiveDiscoveries, pageNumber))
    }
  }, [pageNumber, passiveDiscoveries, reduceToTextItemSelection])

  const removeTextLayerOffset = () => {
    const textLayers = document.querySelector<HTMLElement>(
      '.react-pdf__Page__textContent'
    )

    const { style } = textLayers ?? new HTMLElement()
    style.top = '0'
    style.left = '0'
    style.transform = ''

    const annotationsLayer = document.querySelectorAll<HTMLElement>(
      '.react-pdf__Page__annotations'
    )
    for (let i = 0; i < annotationsLayer.length; i++) {
      annotationsLayer[i].style.height = '0px'
    }
  }

  async function calculateLineNumbers(page: PDFPageItem) {
    const viewport = page.getViewport({
      scale: scale,
    })

    const { rotation } = viewport

    setViewPort({
      viewbox: viewport.viewBox,
      defaultSideways: rotation % 180 !== 0,
    })
    return
  }

  const onPageLoadSuccess = (page: PDFPageProxy) => {
    const pageItem = page as unknown as PDFPageItem
    removeTextLayerOffset()
    calculateLineNumbers(pageItem)
  }

  const renderDiscoveriesSelection = useCallback(
    (textItem: TextLayerItemInternal) => {
      if (!lineMetadata?.isValid(textItem.itemIndex)) {
        return '' as unknown as JSX.Element
      }

      const itemsToHighlight = selectedItems.filter(
        ({ item }) => item.itemIndex === textItem.itemIndex
      )

      const overlappedItems = sortBy(
        itemsToHighlight,
        ({ item }) => item.startIndex
      ).reduce(reduceOverlappedItems, [])

      const textItems = generateTextItems(overlappedItems)

      const reducedItems = zip(
        textItems.elements,
        overlappedItems.map((item, selectedTextItemIndex) => {
          if (item.parent.annotation.startPage === pageNumber) {
            const annotationIds = item.children.map(
              (child) => child.annotation.id
            )
            const discoveryIds = item.children.map(
              (child) => child.discovery?.id
            )
            return (
              <HighlightBase
                id={`highlightBase-${
                  selectedTextItemIndex + 1
                }-page-${pageNumber}`}
                key={`highlightBase-${
                  selectedTextItemIndex + 1
                }-page-${pageNumber}`}
                item={item}
                setHighlightsData={setHighlightsData}
                pageNumber={pageNumber}
                pageRef={pageRef}
                annotationIds={annotationIds}
                discoveryIds={discoveryIds}
              />
            )
          }
          return null
        })
      )
        .flat()
        .filter(Boolean)

      if (reducedItems.length > 0) {
        return <React.Fragment>{reducedItems}</React.Fragment>
      }

      return <React.Fragment>{textItem.str}</React.Fragment>
    },

    [selectedItems, lineMetadata, setHighlightsData, pageNumber]
  )
  const onTextItemSuccess = useCallback(
    (items: TextItem[]) => {
      const { yLookup, lineNumberLookup, isValid } = indexTextItems(
        items,
        viewport,
        pageNumber,
        scale
      )

      setLineMetadata({
        annotation: (
          selection: Selection | null
        ): DiscoveryAnnotation | undefined => {
          return selection ? makeAnnotation(selection, yLookup) : undefined
        },
        selectedItems: ({
          annotation,
          discovery,
        }: WrappedDiscoveryAnnotation): WrappedSelectedTextItem[] => {
          return selectedItemsFromAnnotation(annotation, lineNumberLookup).map(
            (item) => ({
              item: item,
              annotation,
              discovery: discovery,
            })
          )
        },
        lineNumberLookup: lineNumberLookup,
        isValid: isValid,
      })
    },
    [viewport, scale, pageNumber]
  )

  useEffect(() => {
    if (!render && highlightsData.length > 0) {
      setHighlightsData([])
      setSelectedItems([])
    }
    if (!render) {
      setIsOpen(false)
    }
  }, [highlightsData, pageNumber, render])

  const [newAnnotation, setNewAnnotation] = useState<
    AnnotationInput | undefined
  >()

  const renderActionMenuPopper = () => {
    const ranges = []
    const userSelection = window.getSelection()

    if (userSelection) {
      for (let i = 0; i < userSelection.rangeCount; i++) {
        ranges[i] = userSelection.getRangeAt(i)
      }
    }

    if (
      !userSelection ||
      userSelection?.anchorOffset === userSelection?.focusOffset
    ) {
      return
    }

    const getBoundingClientRect = () =>
      userSelection?.getRangeAt(ranges.length - 1).getBoundingClientRect()

    ranges.length > 0 && setAnchorEl({ getBoundingClientRect })
    setIsOpen(true)

    const annotation = lineMetadata?.annotation(userSelection)
    setNewAnnotation(annotation)
  }

  const handleAddDiscovery = () => {
    if (newAnnotation) {
      const annotationInput = clone(newAnnotation)
      delete annotationInput.id
      openAddDiscoveryDrawer(annotationInput)
    }
    window.getSelection()?.removeAllRanges()
    setIsOpen(false)
  }

  useEffect(() => {
    const passiveDiscoveryIds = passiveDiscoveries?.map(
      (discovery) => discovery.id
    )
    setHighlightsData((prev) =>
      prev.filter((highlight) =>
        highlight.discoveryIds.some((id) => passiveDiscoveryIds?.includes(id))
      )
    )
  }, [passiveDiscoveries])

  return (
    <>
      <div
        ref={ref}
        id={pageNumberId(pageNumber)}
        style={{
          height: height * scale,
          marginBottom: theme.spacing(3),
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}
      >
        {render ? (
          <>
            <div
              style={{ position: 'relative' }}
              ref={pageRef}
              onMouseUp={renderActionMenuPopper}
            >
              {highlightsData.map((highlightData, i) => (
                <Highlight
                  key={`highlight-${i + 1}-page-${pageNumber}`}
                  id={`highlight-${i + 1}-page-${pageNumber}`}
                  highlightData={highlightData}
                  pageNumber={pageNumber}
                  activeAnnotation={activeAnnotation}
                  render={render}
                  openViewDiscoveriesDrawer={openViewDiscoveriesDrawer}
                />
              ))}
              <Page
                pageNumber={pageNumber}
                scale={scale}
                onLoadSuccess={onPageLoadSuccess}
                onGetTextSuccess={onTextItemSuccess}
                customTextRenderer={renderDiscoveriesSelection}
              />
              <ActionMenuPopper
                anchorEl={anchorEl}
                isOpen={render && isOpen}
                setOpen={setIsOpen}
                actionItems={[
                  {
                    itemName: 'Add Discovery',
                    onClick: handleAddDiscovery,
                    icon: (
                      <Box
                        mr={1}
                        height="15px"
                        sx={{
                          '& svg': { fill: 'white' },
                        }}
                      >
                        <CommentIcon />
                      </Box>
                    ),
                  },
                ]}
              />
            </div>
          </>
        ) : (
          <LoadingPage
            boxSize={{
              width: width * scale,
              height: height * scale,
            }}
          ></LoadingPage>
        )}
      </div>
    </>
  )
}

export { WrappedPage }
