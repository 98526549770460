import { KpiData } from 'components/Kpi/LargeKpi'
import { FilterData } from 'components/RadioGroup/Filters'
import {
  add,
  differenceInDays,
  isAfter,
  isWithinInterval,
  parseISO,
  sub,
} from 'date-fns'
import {
  ContractingAction,
  ExpirationStatus,
  Initiative,
  useGetAcquisitionTrackerPageDataQuery,
} from 'generated/graphql'
import { compact, filter, find, groupBy, map, orderBy, sum, uniq } from 'lodash'
import { UserContext } from 'pages/Login/User/providers/UserProvider'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { past } from 'utils/date'

import { fundedActionsInFY, FYStart, KpiVerbiage } from '../constants'
import { ChartData, ChartOptions, LineChartData } from '../types'

export interface SortedCalendarDate {
  [key: string]: {
    names: string[]
    type: string
  }
}

export type PlannedAwardDate = Pick<Initiative, 'plannedAwardDate'>
export type AwardDate = Pick<Initiative, 'awardDate'>
export type NeedByDate = Pick<Initiative, 'needByDate'>
export type StatusDetails = Pick<Initiative, 'expirationStatus'>
export type LastUpdated = Pick<Initiative, 'lastUpdated'>

export interface AcquisitionTrackerPageState {
  initiatives: Initiative[]
  keyPerformanceIndicatorsData: KpiData[]
  areaChartProps: AreaProps
  calendarDates: SortedCalendarDate
  isFetching: boolean
  filtersData: FilterData[]
  setFiltersData: Dispatch<SetStateAction<FilterData[]>>
  getFilteredInitiatives: () => Initiative[]
}

export interface AreaChartData {
  xLabel: string
  yLabels: string[]
  data: ChartData[]
}

export interface AreaChartOptions extends ChartOptions {
  xAxis?: {
    xAxis1: string
    xAxis2?: string
  }
}

export interface AreaProps {
  data: AreaChartData
  lineData?: LineChartData
  chartOptions: Partial<AreaChartOptions>
}

export function useAcquisitionTrackerPageState(): AcquisitionTrackerPageState {
  const userContext = useContext(UserContext)
  const tenantId = userContext.user.tenant.id

  const [calendarDates, setCalendarDates] = useState<SortedCalendarDate>({})
  const [initiatives, setInitiatives] = useState<Initiative[]>([])
  const [keyPerformanceIndicatorsData, setKeyPerformanceIndicatorsData] =
    useState<KpiData[]>([])
  const [areaChartProps, setAreaChartProps] = useState<AreaProps>({
    data: {
      xLabel: '',
      yLabels: [],
      data: [],
    },
    chartOptions: {},
  })

  const [filtersData, setFiltersData] = useState<FilterData[]>([])
  const getFilteredInitiatives = useCallback(() => {
    return calculateFilteredInitiatives(filtersData, initiatives)
  }, [filtersData, initiatives])

  const [query] = useGetAcquisitionTrackerPageDataQuery({
    variables: {
      tenantId: tenantId,
    },
    pause: tenantId === '',
  })

  useEffect(() => {
    if (query?.data) {
      const initiatives: Initiative[] = query?.data.listInitiatives
      if (initiatives.length > 0) {
        setInitiatives(initiatives)
        setFiltersData(getInitialFiltersData(initiatives))
      }
    }
  }, [query])

  useEffect(() => {
    if (initiatives.length > 0) {
      const filteredInitiatives = getFilteredInitiatives()
      const unfundedFilteredInitiatives =
        calculateUnfundedInitiatives(filteredInitiatives)
      setKeyPerformanceIndicatorsData([
        calculateApproachingDue(unfundedFilteredInitiatives),
        calculateWithoutProgress(unfundedFilteredInitiatives),
        calculateAnticipatedActions(unfundedFilteredInitiatives),
        calculateInitiativesWithExpiringFunds(unfundedFilteredInitiatives),
        calculateFundedActionsInFiscalYear(
          filteredInitiatives.length - unfundedFilteredInitiatives.length
        ),
      ])
      setAreaChartProps(calculateChartData(unfundedFilteredInitiatives))
      setCalendarDates(generateCalendarProps(filteredInitiatives))
    }
  }, [initiatives, filtersData, getFilteredInitiatives])

  const [isFetching, setIsFetching] = useState<boolean>(query.fetching)
  useEffect(
    () => setIsFetching(query.fetching),
    [setIsFetching, query.fetching]
  )

  return {
    initiatives,
    keyPerformanceIndicatorsData,
    areaChartProps,
    calendarDates,
    isFetching,
    filtersData,
    setFiltersData,
    getFilteredInitiatives,
  }
}

const generateZeroObject = (): ChartData[] => [
  { month: 'OCT' },
  { month: 'NOV' },
  { month: 'DEC' },
  { month: 'JAN' },
  { month: 'FEB' },
  { month: 'MAR' },
  { month: 'APR' },
  { month: 'MAY' },
  { month: 'JUN' },
  { month: 'JUL' },
  { month: 'AUG' },
  { month: 'SEP' },
]

export const calculateAnticipatedActions = (
  initiativesDetails: PlannedAwardDate[]
): KpiData => {
  const today = sub(new Date(), { days: 1 })
  const sixtyDaysFromToday = add(new Date(), {
    days: 60,
  })

  const label = filter(initiativesDetails, (initiative: PlannedAwardDate) =>
    isWithinInterval(parseISO(initiative.plannedAwardDate), {
      start: today,
      end: sixtyDaysFromToday,
    })
  ).length

  return {
    label: label,
    subtitle: KpiVerbiage.Anticipated_Actions,
  }
}

export const calculateFundedActionsInFiscalYear = (
  numOfFundedInitiatives: number
): KpiData => {
  return {
    label: numOfFundedInitiatives,
    subtitle: fundedActionsInFY,
  }
}

export const calculateInitiativesWithExpiringFunds = (
  initiativesDetails: StatusDetails[]
): KpiData => {
  const expiringFundsCount = filter(initiativesDetails, (initiative) => {
    return initiative.expirationStatus === ExpirationStatus.Expiring
  }).length

  return {
    label: expiringFundsCount,
    subtitle: KpiVerbiage.Expiring,
  }
}

export const calculateApproachingDue = (
  initiativesDetails: NeedByDate[]
): KpiData => {
  const today = sub(new Date(), { days: 1 })
  const sixtyDaysFromToday = add(new Date(), {
    days: 60,
  })
  const label = filter(initiativesDetails, (initiative) =>
    isWithinInterval(parseISO(initiative.needByDate), {
      start: today,
      end: sixtyDaysFromToday,
    })
  ).length

  return {
    label: label,
    subtitle: KpiVerbiage.Approaching_Due_Date,
  }
}

export const calculateWithoutProgress = (
  initiativesDetails: LastUpdated[]
): KpiData => {
  const now = new Date()
  now.setHours(0, 0, 0, 1)
  const pastDate = past(now, 7)
  const initiativesApproaching = filter(
    initiativesDetails,
    function (initiative) {
      return new Date(initiative.lastUpdated) < pastDate
    }
  ).length
  return {
    label: initiativesApproaching,
    subtitle: KpiVerbiage.Without_Progress,
  }
}

export const calculateChartData = (initiatives: Initiative[]): AreaProps => {
  const uniquePOCs = uniq(
    initiatives.map((initiatives) => initiatives.contractPoc)
  )
    .map((poc) => ({ [poc as string]: 0 }))
    .reduce((acc, poc) => ({ ...acc, ...poc }))
  const scores = (
    initiatives.map((initiative) => ({
      month: new Date(
        initiative.awardDate ?? initiative.needByDate
      ).getUTCMonth(),
      [initiative.contractPoc as string]: initiative.score,
    })) as unknown as ChartData[]
  ).filter((score) => !isNaN(score.month as number))

  const areaChartData: ChartData[] = generateZeroObject().map((row) => {
    return { ...row, ...uniquePOCs } as ChartData
  })

  const tempScores: { score: number; month: number }[] = []

  scores.forEach((score) => {
    const month = score.month as number
    const key = Object.keys(score).filter((key) => key !== 'month')[0]
    const index = month > 8 ? month - 9 : month + 3

    const oldScore = areaChartData[index][key]
      ? (areaChartData[index][key] as number)
      : 0
    const newScore = score[key] as number

    tempScores.push({ score: newScore, month: month })
    areaChartData[index] = {
      ...areaChartData[index],
      [key]: oldScore + newScore,
    }
  })

  const groups = groupBy(tempScores, (score) => score.month)

  const summedMonths = Object.fromEntries(
    Object.keys(groups).map((month) => [
      month,
      sum(groups[month].map((s) => s.score)),
    ])
  )

  const average =
    Object.keys(summedMonths).reduce(
      (acc, month) => acc + summedMonths[month],
      0
    ) / 12

  const lineChartData = generateZeroObject().map((month) => ({
    ...month,
    value: average,
  }))
  return {
    data: {
      xLabel: 'month',
      yLabels: [...Object.keys(uniquePOCs)],
      data: areaChartData,
    },
    lineData: {
      data: lineChartData,
    },
    chartOptions: {},
  }
}

export const calculateWIPRatio = (
  contractingActions: ContractingAction[]
): number => {
  const today = new Date()

  const filteredActions = filter(
    contractingActions,
    (contractingAction) =>
      contractingAction.dueDate !== undefined &&
      contractingAction.dueDate !== null
  ) as ContractingAction[]
  const sortedActions = orderBy(
    filteredActions,
    (contractAction) => contractAction.dueDate || '',
    'asc'
  )
  if (!sortedActions || sortedActions.length === 0) {
    return 1
  }

  const closestDate = new Date(sortedActions[0].dueDate)
  const farthestDate = new Date(sortedActions[sortedActions.length - 1].dueDate)
  const daysRequired = Math.abs(differenceInDays(farthestDate, closestDate))
  const daysRemaining = Math.abs(differenceInDays(farthestDate, today))

  if (isAfter(today, farthestDate)) {
    return daysRemaining / 5
  } else if (daysRequired === 0) {
    return 1
  } else {
    return daysRemaining / daysRequired
  }
}

export const generateCalendarProps = (
  initiatives: Initiative[]
): SortedCalendarDate => {
  return initiatives
    .flatMap((initiative) =>
      initiative.contractingActions
        .filter((action: ContractingAction) =>
          ['ESIS', 'PSP'].find((v) => action.name.includes(v) && action.dueDate)
        )
        .map((action: ContractingAction) => ({
          name: `${initiative.name} (${action.name})`,
          date: new Date(action.dueDate),
          type: action.name,
        }))
    )
    .reduce((result: SortedCalendarDate, current) => {
      const key = current.date.toLocaleDateString()
      const final: SortedCalendarDate = {
        [key]: {
          names: [current.name],
          type: current.type,
        },
      }

      if (result[key]) {
        final[key].names = uniq([...result[key].names, current.name])
        final[key].type =
          (result[key].type !== current.name &&
            result[key].type !== 'ESIS/PSP') ||
          result[key].type === 'ESIS/PSP'
            ? 'ESIS/PSP'
            : current.type
      }

      return {
        ...result,
        ...final,
      }
    }, {})
}

export enum FilterCategory {
  CONTRACT_POC = 'Contract POC',
  BUSINESS_UNIT = 'Business Unit',
}

const getInitialFiltersData = (initiatives: Initiative[]): FilterData[] => {
  return [
    {
      category: FilterCategory.CONTRACT_POC,
      options: map(
        uniq(compact(map(initiatives, (initiative) => initiative.contractPoc))),
        (contractPoc) => ({
          title: contractPoc,
          selected: false,
        })
      ),
    },
    {
      category: FilterCategory.BUSINESS_UNIT,
      options: map(
        uniq(
          compact(map(initiatives, (initiative) => initiative.businessUnit))
        ),
        (businessUnit) => ({
          title: businessUnit,
          selected: false,
        })
      ),
    },
  ]
}

export const calculateFilteredInitiatives = (
  filters: FilterData[],
  initiatives: Initiative[]
): Initiative[] => {
  let filteredInitiatives: Initiative[] = initiatives

  const contactPocOptions = map(
    filter(
      find(filters, (filter) => filter.category === FilterCategory.CONTRACT_POC)
        ?.options,
      (option) => option.selected
    ),
    (option) => option.title
  )

  const businessUnitOptions = map(
    filter(
      find(
        filters,
        (filter) => filter.category === FilterCategory.BUSINESS_UNIT
      )?.options,
      (option) => option.selected
    ),
    (option) => option.title
  )

  if (contactPocOptions.length > 0) {
    filteredInitiatives = filter(
      initiatives,
      (initiative) =>
        !!initiative.contractPoc &&
        contactPocOptions.includes(initiative.contractPoc)
    )
  }

  if (businessUnitOptions.length > 0) {
    filteredInitiatives = filter(
      initiatives,
      (initiative) =>
        !!initiative.businessUnit &&
        businessUnitOptions.includes(initiative.businessUnit)
    )
  }

  return filteredInitiatives
}

export const calculateUnfundedInitiatives = (
  initiatives: Initiative[]
): Initiative[] =>
  filter(initiatives, function (initiative) {
    return !(
      new Date(initiative.awardDate) < new Date() &&
      new Date(initiative.awardDate) >= FYStart
    )
  })
