import {
  Discovery,
  DiscoveryInput,
  RatingOption,
  Scalars,
} from 'generated/graphql'
import { DiscoveryPositions } from 'hooks/useSortedCriteria'
import React from 'react'

import ViewDiscoveriesDrawerContent from './ViewDiscoveriesDrawerContent'

interface Props {
  discoveries: Discovery[]
  ratings: RatingOption[]
  discoveryPositions: DiscoveryPositions[]

  editDiscovery: (discoveryInput: DiscoveryInput) => Promise<boolean>
  deleteDiscovery: (discoveryId: Scalars['ID']) => Promise<boolean>
}

export const ViewDiscoveriesDrawer = ({
  discoveries,
  ratings,
  discoveryPositions,

  editDiscovery,
  deleteDiscovery,
}: Props): JSX.Element => {
  return (
    <ViewDiscoveriesDrawerContent
      discoveries={discoveries}
      ratings={ratings}
      discoveryPositions={discoveryPositions}
      onEditDiscovery={editDiscovery}
      onDeleteDiscovery={deleteDiscovery}
    />
  )
}
