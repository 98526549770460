import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import PersonIcon from '@mui/icons-material/Person'
import { ClickAwayListener } from '@mui/material'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import { theme } from 'theme'

import { ProfileVariant } from '..'

const PREFIX = 'ProfileView'
export interface Props {
  firstName: string
  familyName: string
  email: string
  logOut: () => void
  variant: ProfileVariant
}

const ProfileView = ({
  firstName,
  familyName,
  email,
  logOut,
  variant,
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = !!anchorEl
  const id = open ? 'simple-popper' : undefined
  const handleClickAway = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <StyledBox>
        <Box className={classes.profileContainer} onClick={handleClick}>
          {variant === 'evaluation' && (
            <Box className={classes.flexColumn} sx={{ mr: theme.spacing(1) }}>
              <PersonIcon
                sx={{
                  color: 'info.dark',
                }}
              />
            </Box>
          )}

          <Box
            className={classes.flexColumn}
            sx={{
              minWidth: 0,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            <Typography
              className={classes.profileName}
              variant="body2"
              sx={{
                minWidth: 0,
                textOverflow: 'ellipsis',
                fontSize: variant === 'navbar' ? '14px' : '',
                lineHeight: '20px',
              }}
              noWrap
            >
              {`${firstName} ${familyName}`}
            </Typography>

            <Typography
              className={classes.profileEmail}
              variant="body2"
              sx={{
                minWidth: 0,
                textOverflow: 'ellipsis',
                fontSize: variant === 'navbar' ? '14px' : '',
                lineHeight: '20px',
              }}
              noWrap
            >
              {email}
            </Typography>
          </Box>

          <Box
            className={classes.flexColumn}
            sx={{
              mr: theme.spacing(1),
              alignSelf: 'start',
            }}
          >
            <ArrowDropDownIcon sx={{ color: theme.typography.body2.color }} />
          </Box>

          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="top-end"
            disablePortal={true}
          >
            <ClickAwayListener onClickAway={handleClickAway}>
              <Paper sx={{ width: '250px' }}>
                <Link
                  className={classes.link}
                  component="button"
                  onClick={logOut}
                >
                  <Box
                    p={
                      variant === 'navbar'
                        ? theme.spacing(2, 1, 2, 2)
                        : theme.spacing(2)
                    }
                  >
                    <Typography variant="subtitle2">Sign Out</Typography>
                  </Box>
                </Link>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </Box>
      </StyledBox>
    </div>
  )
}

export { ProfileView }

const classes = {
  profileContainer: `${PREFIX}-profileContainer`,
  profileName: `${PREFIX}-profileName`,
  profileEmail: `${PREFIX}-profileEmail`,
  link: `${PREFIX}-link`,
  flexColumn: `${PREFIX}-flexColumn`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.profileContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    py: theme.spacing(1),
    cursor: 'pointer',
    height: '40px',
  },
  [`& .${classes.profileName}`]: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  },
  [`& .${classes.profileEmail}`]: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.info.dark,
  },
  [`& .${classes.link}`]: {
    width: '100%',
    textDecoration: 'none !important',
    display: 'flex',
    justifyContent: 'start',
    '&:hover': {
      backgroundColor: theme.palette.info.main,
    },
  },
  [`& .${classes.flexColumn}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
