import { FilterData } from 'components/RadioGroup/Filters'
import { Discovery, EvaluationTask, Maybe, Scalars } from 'generated/graphql'
import { compact, filter, find, map, uniq } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

export const useFilteredDiscoveries = (
  task: EvaluationTask
): {
  filtersData: FilterData[]
  setFiltersData: (data: FilterData[]) => void
  getFilteredDiscoveries: () => Discovery[]
} => {
  const [filtersData, setFiltersData] = useState<FilterData[]>([])

  useEffect(() => {
    setFiltersData(getInitialFiltersData(task))
  }, [task])

  const getFilteredDiscoveries = useCallback(() => {
    return calculateFilteredDiscoveries(filtersData, task)
  }, [filtersData, task])

  return { filtersData, setFiltersData, getFilteredDiscoveries }
}

export enum FilterCategory {
  RATINGS = 'Ratings',
}

const getDiscoveryRatingOptionName = (
  task: EvaluationTask,
  ratingOptionId?: Maybe<Scalars['ID']>
) => {
  return task.factor.discoveryRatingOptions.find(
    (ratingOption) => ratingOption.id === ratingOptionId
  )?.name
}

const getInitialFiltersData = (task: EvaluationTask): FilterData[] => {
  return [
    {
      category: FilterCategory.RATINGS,
      options: map(
        uniq(
          compact(
            map(task.discoveries, (discovery) =>
              getDiscoveryRatingOptionName(task, discovery.rating.option)
            )
          )
        ),
        (ratingOptionName) => ({
          title: ratingOptionName,
          selected: false,
        })
      ),
    },
  ]
}

export const calculateFilteredDiscoveries = (
  filters: FilterData[],
  task: EvaluationTask
): Discovery[] => {
  let filteredDiscoveries: Discovery[] = task.discoveries

  const ratingOptions = map(
    filter(
      find(filters, (filter) => filter.category === FilterCategory.RATINGS)
        ?.options,
      (option) => option.selected
    ),
    (option) => option.title
  )

  if (ratingOptions.length > 0) {
    filteredDiscoveries = filter(
      task.discoveries,
      (discovery) =>
        !!discovery.rating.option &&
        ratingOptions.includes(
          getDiscoveryRatingOptionName(task, discovery.rating.option) || ''
        )
    )
  }

  return filteredDiscoveries
}
