import { Box, Typography } from '@mui/material'
import { ReactComponent as DarkBookIcon } from 'assets/darkBookIcon.svg'
import { ReactComponent as WelcomeImage } from 'assets/landing/cap_hill.svg'
import { ActivityFeedProps, ActivityFeedView } from 'components/ActivityFeed'
import { PageHeader } from 'components/PageHeader'
import { PageSection } from 'components/PageSection'
import TaskAccordion from 'components/TaskAccordion'
import { TaskCardProps } from 'components/TaskCard'
import { format, isBefore } from 'date-fns'
import { EvaluationTask, EvaluationTaskStatus } from 'generated/graphql'
import { groupBy, reduce, uniq } from 'lodash'
import { theme } from 'theme'

import { HomePageState } from '../state'

export interface Props {
  state: HomePageState
}

const Page = ({ state }: Props): JSX.Element => {
  const createSolicitationTasks = (
    tasks: EvaluationTask[]
  ): TaskCardProps[] | undefined => {
    const inCompleteTasks = tasks.filter(
      (task) => task.status !== EvaluationTaskStatus.Completed
    )
    const solicitations = groupBy(
      inCompleteTasks,
      (task) => task.proposal.solicitationId
    )
    const getNextDate = (tasks: EvaluationTask[]): string => {
      const soonest = new Date(
        reduce(tasks, (soonestTask, currentTask) => {
          const soonest = new Date(soonestTask.dueAt)
          const current = new Date(currentTask.dueAt)
          return isBefore(soonest, current) ? soonestTask : currentTask
        })?.dueAt
      )
      return format(soonest, 'yyyy-MM-dd')
    }
    const taskCards = Object.keys(solicitations).map((solicitation) => {
      return {
        solicitationId: solicitation,
        taskDescription: `You have ${solicitations[solicitation].length} open tasks`,
        taskName: solicitations[solicitation][0].proposal.name,
        taskTypes: uniq(
          solicitations[solicitation].map((task) => task.taskType)
        ),
        taskDueOrCompletedDate: getNextDate(solicitations[solicitation]),
        linkTo: `/solicitations/${encodeURIComponent(
          solicitations[solicitation][0].proposal.solicitationId
        )}/dashboard`,
      }
    })

    return taskCards
  }
  return (
    <Box
      id="page-view"
      width="100%"
      display="flex"
      flexDirection="column"
      ml={1}
      height="fit-content"
    >
      <Box mt={3.5}>
        <PageHeader
          header={
            <>
              <Typography variant="inherit" display="inline-block">
                Welcome back,
              </Typography>
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  display: 'inline-block',
                }}
                variant="inherit"
              >
                &nbsp;{state.user.firstName}
              </Typography>
            </>
          }
        />
      </Box>

      <PageSection
        inlineLeftChildren={
          <Box alignSelf="flex-start" width="100%" pr={4} mt={6}>
            <TaskAccordion
              accordions={[
                {
                  accordionTitle: 'Tasks Due',
                  tasks: state.tasks && createSolicitationTasks(state.tasks),
                  emptyMessage: 'You have no tasks due at this time',
                  icon: <DarkBookIcon />,
                },
              ]}
            />
          </Box>
        }
        inlineRightChildren={
          <Box position="relative" top="-80px" pr={6}>
            <WelcomeImage width="100%" />
            <Box mt={7.5}>
              <ActivityFeedView
                {...(state.activityFeed as ActivityFeedProps)}
              />
            </Box>
          </Box>
        }
        leftProportion={7}
        rightProportion={5}
      />
    </Box>
  )
}

export { Page }
