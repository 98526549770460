import { Criteria, Discovery, EvaluationTask } from 'generated/graphql'
import { filter, flatMap, orderBy } from 'lodash'
import { useMemo } from 'react'

export type DiscoveryPositions = {
  order: string
  id: string
}

export interface SortedCriteria extends Criteria {
  discoveries?: Array<Discovery & { order?: string }>
}

export const useSortedCriteria = (
  evaluationTask?: EvaluationTask
): {
  sortedCriteria: SortedCriteria[]
  discoveryPositions: DiscoveryPositions[]
} => {
  const discoveryPositions: DiscoveryPositions[] = []
  const sortedCriteria: SortedCriteria[] = useMemo(() => {
    return orderBy(
      evaluationTask?.factor.criteria ?? [],
      (item) => item.name,
      'asc'
    )
  }, [evaluationTask?.factor.criteria])

  sortedCriteria.forEach((criteria, criteriaIndex) => {
    const discoveries = filter(
      evaluationTask?.discoveries ?? [],
      (discovery) => discovery.criteria.id === criteria.id
    )
    const childTaskDiscoveries = flatMap(
      evaluationTask?.children ?? [],
      (childTask) =>
        filter(
          childTask?.discoveries ?? [],
          (discovery) => discovery.criteria.id === criteria.id
        )
    )

    criteria.discoveries = orderBy(
      [...discoveries, ...childTaskDiscoveries],
      (item) => item.createdAt,
      'asc'
    )

    criteria.discoveries.forEach((discovery, discoveryIndex) => {
      discovery.order = `${criteriaIndex + 1}.${discoveryIndex + 1}`
      discoveryPositions.push({ order: discovery.order, id: discovery.id })
    })
  })
  return { sortedCriteria, discoveryPositions }
}
