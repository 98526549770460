import CheckIcon from '@mui/icons-material/Check'
import {
  alpha,
  FormControl,
  MenuItem,
  outlinedInputClasses,
  Select,
  selectClasses,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import { SharedProps } from 'components/form/SelectionBox/index'
import SelectionErrors from 'components/form/SelectionBox/SelectionErrors'
import SelectionLabel, {
  Props as SelectionLabelProps,
} from 'components/form/SelectionBox/SelectionLabel'
import { useCallback, useMemo, useState } from 'react'
import { FieldValues } from 'react-hook-form'
import { theme } from 'theme'

export interface Props<T> extends SharedProps<T>, SelectionLabelProps {
  field: FieldValues
  itemLabel: keyof T
  itemValue: keyof T
  defaultValue?: string
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const Selection = <T extends Record<string, any>>(
  props: Props<T>
): JSX.Element => {
  const {
    disabled,
    field,
    hideLabel,
    label,
    defaultOption,
    defaultValue,
    hideDefaultOption,
    items,
    itemValue,
    itemLabel,
    renderValue,
    useFormHook,
    hideErrorMessage,
    readonly,
  } = props
  const { formState } = useFormHook
  const [dropDownWidth, setDropDownWidth] = useState(100)
  const selectRef = useCallback((node) => {
    if (node) {
      setDropDownWidth(node.node.getBoundingClientRect().width / (100 / 85))
    }
  }, [])

  const listItems = useMemo(() => {
    if (hideDefaultOption) {
      return items
    }
    return [
      { [itemValue]: defaultValue || '', [itemLabel]: defaultOption } as T,
      ...items,
    ]
  }, [
    items,
    hideDefaultOption,
    itemLabel,
    itemValue,
    defaultOption,
    defaultValue,
  ])

  const selectedItem = useMemo(() => {
    const obj = items.find((item) => item[itemValue] === field.value)
    return obj ?? ''
  }, [field, items, itemValue])

  const getBackgroundColor = useMemo(() => {
    if (disabled) {
      return alpha(theme.palette.info.dark, 0.15)
    }

    if (readonly) {
      return theme.palette.info.light
    }

    return formState.errors?.[label]
      ? alpha(theme.palette.error.light, 0.25)
      : theme.palette.background.paper
  }, [disabled, label, formState, readonly])

  const classes = styles(props, { getBackgroundColor })

  const getMenuItem = (item: T) => {
    return (
      <Typography
        variant="body2"
        color={
          disabled ? 'info.dark' : readonly ? 'text.secondary' : 'text.primary'
        }
        sx={classes.ellipsis}
        width={dropDownWidth}
      >
        {typeof item[itemLabel] === 'function'
          ? item[itemLabel](item, field)
          : item[itemLabel]}
      </Typography>
    )
  }

  const getPlaceHolder = () => {
    const color = disabled
      ? theme.palette.info.dark
      : readonly
      ? theme.palette.text.secondary
      : ''
    return (
      <Typography
        variant="body2"
        sx={{
          color,
          '-webkit-text-fill-color': color,
        }}
      >
        {defaultOption}
      </Typography>
    )
  }

  const getCustomSelectedValue = (): JSX.Element => {
    return (
      <Typography variant="body2" color="info.dark">
        {renderValue && renderValue({ item: selectedItem as T, field })}
      </Typography>
    )
  }

  const getRenderValue = () => {
    if (renderValue) {
      return selectedItem ? getCustomSelectedValue() : getPlaceHolder()
    }
    return selectedItem ? getMenuItem(selectedItem) : getPlaceHolder()
  }

  return (
    <FormControl fullWidth variant="outlined" disabled={disabled || readonly}>
      {!hideLabel && <SelectionLabel label={label} />}
      <Select
        {...field}
        displayEmpty={!hideDefaultOption}
        renderValue={getRenderValue}
        sx={classes.select}
        inputRef={selectRef}
      >
        {listItems.map((item, index) => (
          <MenuItem key={index} value={item[itemValue]} sx={classes.menuItem}>
            {getMenuItem(item)}
            {field.value === item[itemValue] && (
              <CheckIcon sx={{ color: 'primary.main' }} />
            )}
          </MenuItem>
        ))}
      </Select>
      {!hideErrorMessage && formState.errors && (
        <SelectionErrors label={label} errors={formState.errors} />
      )}
    </FormControl>
  )
}

const styles = <T extends Record<string, unknown>>(
  props: Partial<Props<T>>,
  options: Record<string, unknown>
): Record<string, SxProps<Theme>> => {
  const formHook = props.useFormHook
  const { disabled, hideLabel, label = '', readonly } = props

  return {
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      '&:hover': { backgroundColor: 'info.main' },
      '&.Mui-selected': {
        backgroundColor: (theme) =>
          `${theme.palette.background.paper}!important`,
        '&:hover': {
          backgroundColor: (theme) => `${theme.palette.info.main}!important`,
        },
      },
    },
    select: {
      marginTop: hideLabel ? 0 : 6,
      [`& .${selectClasses.outlined}`]: {
        backgroundColor: `${options.getBackgroundColor}!important`,
        borderRadius: 1,
        padding: (theme) => theme.spacing(1, 1),
      },
      [`&.${selectClasses.disabled}`]: {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: readonly ? alpha(theme.palette.info.dark, 0.2) : '',
        },
      },
      [`&.${outlinedInputClasses.focused}`]: {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: formHook?.formState?.errors?.[label]
            ? 'error.light'
            : 'info.dark',
        },
      },
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: formHook?.formState?.errors?.[label]
          ? 'error.light'
          : '#DADCE0',
        borderStyle: 'solid',
        borderWidth: disabled
          ? '0'
          : formHook?.formState?.errors?.[label]
          ? '3px'
          : '1px',
      },
    },
  }
}

export default Selection
