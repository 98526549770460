import { alpha, Box, ClickAwayListener, Typography } from '@mui/material'
import { ReactComponent as CommentIcon } from 'assets/discoveryCommentIcon.svg'
import { ReactComponent as CommentIconActive } from 'assets/discoveryCommentIconActive.svg'
import { ReactComponent as CommentIconDisabled } from 'assets/discoveryCommentIconDisabled.svg'
import React, { useState } from 'react'
import { theme } from 'theme'

export interface Props {
  forceActive?: boolean
  keepActive?: boolean
  disabled?: boolean
  children?: React.ReactNode
  secondaryChildren?: React.ReactNode

  setActive?: (input: boolean) => void
  editingOrDeleting: boolean
}

export const GenericDiscoveryBox = ({
  forceActive,
  keepActive,
  disabled,
  children,
  secondaryChildren,

  setActive: setContentActive,
  editingOrDeleting,
}: Props): JSX.Element => {
  const [active, setActive] = useState(forceActive || false)

  const handleSetActive = (input: boolean) => {
    if (!forceActive) {
      setActive(input)
      setContentActive?.(input)
    }
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        active && !keepActive && handleSetActive(false)
      }}
    >
      <Box
        role="presentation"
        id={'generic-discovery-box'}
        sx={Object.assign(
          {
            p: 3,
            border: `1px solid ${theme.palette.info.main}`,
          },
          !disabled &&
            !keepActive && {
              '&:hover': {
                cursor: 'pointer',
                border: `1px solid ${alpha(theme.palette.info.dark, 0.6)}`,
                boxShadow: `0px 3px 7px ${alpha(theme.palette.info.dark, 0.5)}`,
              },
            }
        )}
        onClick={() => {
          if (!keepActive) {
            handleSetActive(!active)
          }
        }}
      >
        <Box id={'first-row'} display={'flex'}>
          <Box id={'icon-column'} mr={2}>
            {disabled ? (
              <CommentIconDisabled />
            ) : active ? (
              <CommentIconActive />
            ) : (
              <CommentIcon />
            )}
          </Box>
          <Box id={'main-column'} width={1}>
            {disabled ? (
              <Typography variant="h6" color="info.dark">
                No Discoveries
              </Typography>
            ) : (
              children
            )}
          </Box>
        </Box>
        {(!disabled && active && secondaryChildren) ||
          (editingOrDeleting && (
            <Box id={'second-row'} mt={3}>
              {secondaryChildren}
            </Box>
          ))}
      </Box>
    </ClickAwayListener>
  )
}
