import { ReactComponent as Completed } from 'assets/activityFeed/completed.svg'
import { ReactComponent as ConsensusReady } from 'assets/activityFeed/consensusReady.svg'
import { ReactComponent as Document } from 'assets/activityFeed/document.svg'
import { ReactComponent as EvaluationStarted } from 'assets/activityFeed/evaluationStarted.svg'
import { ReactComponent as ReportReady } from 'assets/activityFeed/reportReady.svg'
import { ReactComponent as Warning } from 'assets/activityFeed/warning.svg'

export const homepageActivityFeeds = {
  title: 'ACTIVITY FEED',
  subtitle: 'Overview of the last 10 days',
  items: [
    {
      icon: <Completed />,
      title: 'All tasks completed for SEMATS',
      date: 'Today',
    },
    {
      icon: <ReportReady />,
      title: 'Report ready for Deloitte on SEMATS',
      date: 'Today',
    },
    {
      icon: <Warning />,
      title: 'Evaluation behind schedule on Sandy Relief for FTI',
      date: '2 days ago',
    },
    {
      icon: <ConsensusReady />,
      title: 'Consensus ready for Warrior Care Program for IEM',
      date: '4 days ago',
    },
  ],
}

export const solicitationActivityFeeds = {
  title: 'ACTIVITY FEED',
  subtitle: 'Overview of the last 10 days',
  items: [
    {
      icon: <Document />,
      title: 'New document(s) added',
      date: 'Today',
      user: 'Amanda Blackwell',
    },
    {
      icon: <EvaluationStarted />,
      title: 'IEM evaluation started',
      date: 'Today',
      user: 'Eric Westleson',
    },
    {
      icon: <ConsensusReady />,
      title: 'Consensus ready for IEM',
      date: '2 days ago',
      user: 'Joe Vidalis',
    },
    {
      icon: <ReportReady />,
      title: 'Report ready for Deloitte',
      date: '4 days ago',
      user: 'Joe Vidalis',
    },
  ],
}
